// url
export const BASE_URL = process.env.VUE_APP_BASE_URL;
export const BASE_URL_API = process.env.VUE_APP_BASE_URL_API;
export const BASE_URL_UI = process.env.VUE_APP_BASE_URL_UI;


// Application Pre-configuration
export const CURRENCY_SYMBOL = '£';
export const CURRENCY_NAME = 'GBP';
export const APP_LOCALE = 'en-GB';

export const RESET_PASSWORD_URL = `${BASE_URL_UI}/auth/forget-password`;

// Auth
export const AUTH_TOKEN_NAME = 'accessToken';

/**
 * Time format like: 02:05 AM
 * @type String
 */
export const TIME_SELECTS_FORMAT = 'hh:mm a';

/**
 * Date format like: 2021-06-26
 * @type String
 */
export const DATE_SELECTS_FORMAT = 'yyyy-MM-dd';
export const APP_TIMEZONE = process.env.VUE_APP_SITE_TIMEZONE;

/**
 * DateTime format like: 27th Sep @06:14 PM
 * @type String
 */
export const DATETIME_FORMAT_1 = 'do MMM @hh:mm a';

// list item action types
export const LIST_ITEM_ACTION_VIEW = 'view';
export const LIST_ITEM_ACTION_UPDATE = 'update';
export const LIST_ITEM_ACTION_UPDATE_STATUS = 'status';
export const LIST_ITEM_ACTION_DELETE = 'delete';
export const LIST_ITEM_ACTION_PRINT = 'print';

export const DEFAULT_DELETE_HEADER = 'Are you sure, you want to Delete?';
export const DEFAULT_DELETE_MESSAGE = 'This action cannot be undone.?';
export const DEFAULT_DELETE_TYPE = 'warning';

export const AD_MAX_IDLE_TIME = parseInt(process.env.VUE_APP_AD_MAX_IDLE_SECS);
export const AD_CHECK_TIME = parseInt(process.env.VUE_APP_AD_TIME_CHECK_SECS);
export const AD_CHANGE_TIME = parseInt(process.env.VUE_APP_AD_CHANGE_TIME);
