import permissions from '@/utils/permissions'
const routeMap = {

  auth: {
    path: '/auth',
    login: {
      name: 'Login',
      path: '/auth/login',
      title: 'Login',
      icon: '',
      permission: ''
    }
  },

  error: {
    unauthorized: {
      path: '/unauthorized',
      name: 'Unauthorized',
      title: 'Dashboard',
      permission: ''
    },
    notFound: {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      title: 'Not Found',
      permission: ''
    }
  },

  dashboard: {
    name: 'Dashboard',
    path: '',
    title: 'Dashboard',
    icon: '',
    permission: [permissions.admin_login, permissions.dashboard_view]
  },

  order: {
    name: 'Orders',
    path: '/orders',
    title: 'Orders',
    icon: '',
    permission: [permissions.order_view]
  },

  orderMenu: {
    name: 'OrderMenu',
    path: '/order-menu',
    title: 'Order Menu',
    icon: '',
    permission: [permissions.order_view]
  },

  reservation: {
    name: 'Reservation',
    path: '/reservation',
    title: 'Reservation',
    icon: '',
    permission: [permissions.reservation_view]
  },

  menuCategories: {
    name: 'Categories',
    path: '/menu/categories',
    title: 'Categories',
    icon: '',
    permission: [permissions.menu_category_view]
  },
  menuItems: {
    name: 'Items',
    path: '/menu/items',
    title: 'Items',
    icon: '',
    permission: [permissions.menu_item_view]
  },
  menuAddons: {
    name: 'Addons',
    path: '/menu/addons',
    title: 'Addons',
    icon: '',
    permission: [permissions.menu_addon_view]
  },
  menuSetMenu: {
    name: 'SetMenu',
    path: '/menu/set-menu',
    title: 'Set Menu',
    icon: '',
    permission: [permissions.menu_set_menu_view]
  },

  aclUsers: {
    name: 'Users',
    path: '/acl/users',
    title: 'Users',
    icon: '',
    permission: [permissions.acl_user_view]
  },
  aclGroups: {
    name: 'Groups',
    path: '/acl/groups',
    title: 'Groups',
    icon: '',
    permission: [permissions.acl_group_view]
  },
  aclPermissions: {
    name: 'Permissions',
    path: '/acl/permissions',
    title: 'Permissions',
    icon: '',
    permission: [permissions.acl_permission_view]
  },

  opening: {
    name: 'Opening',
    path: 'opening',
    title: 'Opening Hours',
    icon: '',
    permission: [permissions.opening_hour_view]
  },

  offer: {
    name: 'Offer',
    path: 'offer',
    title: 'Offer',
    icon: '',
    permission: [permissions.offer_view]
  },

  inactivityAd: {
    name: 'InactivityAd',
    path: 'inactivity-ad',
    title: 'Inactivity Ad',
    icon: '',
    permission: [permissions.app_setting_update]
  },

  customers: {
    name: 'Customers',
    path: 'customers',
    title: 'Customers',
    icon: '',
    permission: [permissions.customers_view]
  },

  deliveryCharge: {
    name: 'DeliveryCharge',
    path: 'delivery-charge',
    title: 'Delivery Charges',
    icon: '',
    permission: [permissions.delivery_charge_view]
  },

  seo: {
    name: 'SEOMeta',
    path: 'seo-meta',
    title: 'SEO Meta',
    icon: '',
    permission: [permissions.seo_view]
  },

  settings: {
    name: 'Settings',
    path: 'settings',
    title: 'Settings',
    icon: '',
    permission: [permissions.app_setting_view]
  }

};

export default routeMap;
