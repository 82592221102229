<template>
  <Button
    :label="iconOnly? '' : label"
    :loading="buttonLoading"
    :class="[
      {
        'p-button-outlined' : outlined
      },
      buttonType? buttonStyle(buttonType) : '',
      iconOnly? 'p-button-secondary p-button-outlined' : '',
      classes
      ]"
    :icon="icon"
  />
</template>

<script>
import Button from 'primevue/button';

import { defineComponent, watch, ref } from 'vue';

export default defineComponent({
  name: 'ButtonComp',
  components: { Button },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Button'
    },
    iconOnly: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    buttonType: {
      type: String,
      required: false,
      validator (value) {
        const allowed = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

        if (!allowed.includes(value)) {
          throw new Error(`Allowed button types are: ${allowed} provided '${value}'`);
        }

        return true;
      }
    },
  },

  setup (props) {

    const buttonLoading = ref(false);
    watch(() => props.loading, (data) => buttonLoading.value = data);

    const buttonStyle = (type) => {

      if (!type) return;

      let buttonStyle = '';

      switch (type) {
        case 'primary':
          buttonStyle = '';
          break;

        case 'secondary':
          buttonStyle = 'p-button-secondary';
          break;
        case 'success':
          buttonStyle = 'p-button-success';
          break;

        case 'info':
          buttonStyle = 'p-button-info';
          break;

        case 'warning':
          buttonStyle = 'p-button-warning';
          break;

        case 'danger':
          buttonStyle = 'p-button-danger';
          break;

      }
      return buttonStyle;
    };

    return {
      buttonLoading,
      buttonStyle
    };
  }
});
</script>
