import Menu from '@/Services/Menu';
import {
  DATA_MODIFY_ACTION_ADD, DATA_MODIFY_ACTION_DELETE, DATA_MODIFY_ACTION_UPDATE,
  SET_DATA, SET_DATA_FETCHED,
  SET_ERROR_MSG,
  SET_ERRORS,
  SET_LOADING
} from '@/store/modules/default/types';
import { touchCollection } from '@/utils/Helper';

const statePath = 'items';

export default {

  setItemLoading: ({ commit }, payload) => commit(SET_LOADING, { path: statePath, data: payload }),
  setItemDataFetched: ({ commit }, payload) => commit(SET_DATA_FETCHED, { path: statePath, data: payload }),
  setItemData: ({ commit }, payload) => commit(SET_DATA, { path: statePath, data: payload }),
  setItemErrors: ({ commit }, payload) => commit(SET_ERRORS, { path: statePath, data: payload }),
  setItemErrorMsg: ({ commit }, payload) => commit(SET_ERROR_MSG, { path: statePath, data: payload }),


  fetchItems: async ({ dispatch }) => {

    dispatch('setItemLoading', true);

    try {

      const response = await Menu.getItems();
      const { data } = response.data;

      await dispatch('setItemData', data);
      await dispatch('setItemDataFetched', true);

    } catch (e) {

      await dispatch('setItemData', {});
      await dispatch('setItemErrors', e?.errors);
      await dispatch('setItemErrorMsg', e.message);

    }

    dispatch('setItemLoading', false);

  },

  createItem: async ({ dispatch, state }, payload) => {

    const response = await Menu.createItem(payload);
    const { data } = response.data;

    const newItems = {
      ...state.items.data,
      items: touchCollection(state.items.data.items, data, DATA_MODIFY_ACTION_ADD)
    };

    await dispatch('setItemData', newItems);

    return data;
  },

  updateItem: async ({ dispatch, state }, { id, data }) => {

    const response = await Menu.updateItem(id, data);
    const { data: result } = response.data;

    const newItems = {
      ...state.items.data,
      items: touchCollection(state.items.data.items, result, DATA_MODIFY_ACTION_UPDATE)
    };

    await dispatch('setItemData', newItems);

    return data;
  },

  deleteItem: async ({ dispatch, state }, payload) => {

    const response = await Menu.destroyItem(payload);
    const { data } = response.data;

    const newItems = {
      ...state.items.data,
      items: touchCollection(state.items.data.items, payload, DATA_MODIFY_ACTION_DELETE)
    };

    await dispatch('setItemData', newItems);

    return data;
  },

};
