<template>
  <Dialog
    v-model:visible="state.showModal"
    :draggable="false"
    modal
    append-to="body"
    :show-header="!!titleText"
    :closable="false"
    :class="[
      customClass,
      {'fill-btn-footer': footerFillButtons},
      {'info-modal': (mode === 'info')},
      {'update-modal': (mode === 'update')},
      {'change-modal': (mode === 'change')},
      {'delete-modal': (mode === 'delete')},
    ]"
    :style="{
      maxWidth,
      maxHeight
    }"
  >
    <template #header>
      <slot name="header">
        <div
          :class="`d-flex justify-content-between align-items-center p-3 ${headerClasses}`"
          style="width:100%"
        >
          <div>
              <span
                v-if="titleIconClass"
              >
                <i :class="['me-1', titleIconClass]" />
              </span>
            <span>{{ titleText }}</span>
          </div>
          <div class="close-parent">
            <i
              class="pi pi-times cursor-pointer custom-close-icon"
              @click="fireAction('hide', false)"
            />
          </div>
        </div>
      </slot>
    </template>

    <slot></slot>

    <template #footer v-if="showActionButton">
      <slot name="footer">

        <!--fill button layout-->
        <template v-if="footerFillButtons">
          <div
            class="modal-footer custom"
          >
            <div class="btn-left">
              <button
                type="button"
                class="btn btn-link btn-block"
                @click="fireAction('hide', false)"
                :disabled="state.buttonLoading"
              >
                {{ cancelButtonText }}
              </button>
            </div>
            <div class="btn-right">
              <button
                type="button"
                class="btn btn-link text-nowrap btn-block d-flex"
                @click="fireAction('submit', true)"
                :disabled="state.buttonLoading"
              >
                <Spinner v-if="state.buttonLoading" size="18" classes="me-1" />
                {{ okButtonText }}
              </button>
            </div>
          </div>
        </template>

        <!--button layout-->
        <template v-else>
          <ButtonComp
            v-if="showCancelButton"
            :label="cancelButtonText"
            buttonType="secondary"
            outlined
            @click="fireAction('hide', false)"
          />

          <ButtonComp
            :label="okButtonText"
            :loading="state.buttonLoading"
            @click="fireAction('submit', true)"
          />
        </template>

      </slot>
    </template>
  </Dialog>
</template>

<script>

import { watch, defineComponent, reactive } from 'vue';
import Dialog from 'primevue/dialog';
import ButtonComp from '@/components/Util/ButtonComp';
import Spinner from '@/components/Util/Spinner';

export default defineComponent({
  name: 'ModalComp',
  emits: ['hide', 'submit'],
  components: {
    ButtonComp,
    Dialog,
    Spinner
  },

  props: {

    show: {
      type: Boolean,
    },

    showOkButton: {
      type: Boolean,
      default: true
    },

    showCancelButton: {
      type: Boolean,
      default: true
    },

    okButtonText: {
      type: String,
      default: 'Submit',
    },

    cancelButtonText: {
      type: String,
      default: 'Cancel'
    },

    loading: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: 'create',
      validator: (val) => {
        const modes = ['create', 'update', 'change', 'delete', 'info'];
        const validation = modes.includes(val);

        if (!validation) throw new Error(`Mode can be one of '${modes}' got '${val}'`);
        return validation;
      }
    },

    showActionButton: {
      type: Boolean,
      default: true
    },

    titleText: {
      type: String,
      default: '',
    },

    titleIconClass: String,

    customClass: {
      type: String,
      default: ''
    },

    headerClasses: {
      type: String,
      default: ''
    },

    maxWidth: {
      type: String,
      default: '500px'
    },

    maxHeight: {
      type: String,
      default: '90%'
    },

    footerFillButtons: {
      type: Boolean,
      default: true
    }

  },

  setup (props, { emit }) {

    const state = reactive({
      showModal: false,
      buttonLoading: false,
    });

    const hideModal = () => {
      state.showModal = false;
    };
    const fireAction = (eventName, value) => emit(eventName, value);

    // Watcher
    watch(() => props.show, (nv) => {

      if (nv) {
        state.showModal = true;

        // scroll down when popup opens for app
        const scrollDown = 15;
        const scrollPos = window.scrollY;
        if (scrollPos < scrollDown) window.scrollTo(0, scrollDown);

      } else {
        hideModal();
      }

    });
    watch(() => props.loading, (nv) => state.buttonLoading = nv);

    return {
      state,
      fireAction,
      hideModal
    };
  },
});
</script>

<style lang="scss">
@import "../../assets/sass/variables";

$closeIconColor: rgba(255, 255, 255, 0.60);

.custom-close-icon {
  font-weight: 700;
  color: $closeIconColor;
}

.p-dialog {
  &-content{
    overflow: auto !important;
  }
  .p-dialog-header {
    background-color: $primary !important;
    color: $white !important;
    padding: 0 !important;
    font-weight: 700;
  }

  &.info-modal {
    .p-dialog-header {
      background-color: $secondary !important;
      color: $black !important;

      .custom-close-icon {
        color: darken($closeIconColor, 85) !important;
      }
    }
  }

  &.update-modal {
    .p-dialog-header {
      background-color: $warning !important;
      color: $gray-dark !important;

      .custom-close-icon {
        color: darken($closeIconColor, 80) !important;
      }
    }
  }

  &.delete-modal {
    .p-dialog-header {
      background-color: $danger !important;
      color: $white !important;

      .custom-close-icon {
        color: $closeIconColor !important;
      }
    }
  }

  &.change-modal {
    .p-dialog-header {
      background-color: $dark !important;
      color: $white !important;

      .custom-close-icon {
        color: $closeIconColor !important;
      }
    }
  }

  .p-dialog-content {
    padding: 0 1.0rem .3rem 1.0rem !important;
  }

  .p-dialog-footer {
    border-top: 1px solid #e3e3e3 !important;
    display: flex !important;
    padding: 15px !important;
    justify-content: flex-end !important;

  }
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, .75) !important;
  transition-duration: 0.2s !important;
}

// fill button footer
.modal-footer {
  text-align: center !important;
  border-bottom-left-radius: calc(.3rem - 1px) !important;
  border-bottom-right-radius: calc(.3rem - 1px) !important;
  flex-wrap: wrap !important;
  border-top: 1px solid #e4e7f2 !important;

  &.custom {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-wrap: nowrap !important;
    padding: 0 !important;
    text-align: center !important;
    width: 100% !important;

    .btn-left {
      display: flex !important;
      justify-content: center !important;
      width: 100% !important;
      border-right: 1px solid #e4e7f2 !important;
    }

    .btn-right {
      display: flex !important;
      justify-content: center !important;
      width: 100% !important;
    }

    .btn-link {
      color: #2e323c !important;
      font-size: .9rem !important;
      font-weight: 700 !important;
      padding: 1rem !important;
      text-transform: uppercase !important;
      text-decoration: unset !important;
    }

  }
}

.fill-btn-footer {
  .p-dialog-footer {
    padding: 0 !important;
  }
}

</style>

<style lang="scss" scoped>
  .close-parent {
    position: relative;

    .custom-close-icon {
      position: absolute;
      font-size: 25px;
      left: -30px;
      top: -23px;
      padding: 10px;
    }
  }
</style>
