import defaultState from '../default/state';
import defaultMutations from '../default/mutations';
import actions from './actions';
import { collect } from 'collect.js';

export default {
  namespaced: true,

  state: () => ({
    ...defaultState,
  }),

  getters: {
    allSettings: (state) => state.data.settings || {},

    getSettings: (state) => {
      // the fallback content added because if we expect string
      return (keys, singleValueOnly = false, fallback = {}) => {

        // if setting is unavailable
        const settingList = state.data?.settings;
        if (!settingList) return fallback;

        // construct required keys
        const keysTypeIsString = (typeof keys === 'string');
        let requiredKeys = keysTypeIsString ? [keys] : [...keys];

        if (singleValueOnly) {

          let result = collect(settingList)
            .flatten(1)
            .where('key', keys)
            .first()
          ;

           return result?.value;
        }

        const result = {};

        // constructing key value based return data
        collect(settingList).flatten(1)
          .filter(setting => requiredKeys.includes(setting.key))
          .map(setting => {
            result[setting.key] = setting.value;
          })
        ;

        return (Object.keys(result).length === 1)
          ? Object.values(result).pop()
          : result
          ;
      };
    }
  },

  mutations: {
    ...defaultMutations
  },

  actions: {
    ...actions,
  }

};
