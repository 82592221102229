import {
  SET_BOOTSTRAPPED, SET_EXPANDED, SET_HOVERED, SET_NEW_ORDER_COUNT, SET_NEW_RESERVATION_COUNT, SET_SOUND, SET_SOUND_PLAY
} from './types';
import { SET_LOADING } from '@/store/modules/default/types';
import { getFromLocalStorage } from '@/utils/Helper';
import { AUTH_TOKEN_NAME } from '@/utils/constants';

export default {

  setLoading: ({ commit, dispatch }, payload) => commit(SET_LOADING, payload),

  setBootstrap: async ({ state, dispatch, commit }) => {

    // Set Application TO Loading State
    await dispatch('setLoading', true);

    if (state.bootstrapped) {
      return;
    }

    const token = getFromLocalStorage(AUTH_TOKEN_NAME, false);

    if (!token) {
      await dispatch('user/setAuthenticated', false, { root: true });
      await commit(SET_BOOTSTRAPPED, true);

      // Turn Of Loading
      await dispatch('setLoading', false);
      return;
    }



    await dispatch('user/setTokenValidated', true, { root: true });

    await commit(SET_BOOTSTRAPPED, true);

    // load advertisements
    await dispatch('inactivityAd/getAds', null, { root: true })

    // Turn Of Loading
    await dispatch('setLoading', false);

  },

  // layout
  setSidebarHover: ({ state, commit }, payload) => {
    commit(SET_HOVERED, payload);
  },
  setSidebarExpanded: ({ state, commit }, payload) => commit(SET_EXPANDED, payload),

  // new order
  setNewOrderSound: ({ state, commit }, payload) => {
    commit(SET_SOUND, payload);

    // when sound is disabling, stop any playing sound
    if (!payload) {
      commit(SET_SOUND_PLAY, false);
    }
  },

  // stops currently playing sound, doesn't disables it
  stopNewOrderSound: ({ commit }) => {
    commit(SET_SOUND, false);

    // enable it again so it can play again
    setTimeout(() => {
      commit(SET_SOUND, true);
    }, 800);
  },


  setNewOrderSoundPlay: ({ state, commit }, payload) => {
    commit(SET_SOUND_PLAY, payload);

    // if playing sound, stop it after
    if (payload) {
      setTimeout(() => commit(SET_SOUND_PLAY, false), 100);
    }
  },

  resetNewOrdersCount: ({ commit }) => commit(SET_NEW_ORDER_COUNT, 0),
  incrementNewOrderCount: ({ state, commit }) => commit(SET_NEW_ORDER_COUNT, (state.newOrder.count + 1)),

  resetNewReservationCount: ({ commit }) => commit(SET_NEW_RESERVATION_COUNT, 0),
  incrementNewReservationCount: ({ state, commit }) => commit(SET_NEW_RESERVATION_COUNT, (state.newReservation.count + 1)),

};
