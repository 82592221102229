import Api from '@/Services/Api';
import http from '@/Plugins/http';
import {
  GET_ACL_USERS, UPDATE_ACL_USER,
  GET_ACL_GROUPS, CREATE_ACL_GROUP, UPDATE_ACL_GROUP, DELETE_ACL_GROUP,
  GET_ACL_PERMISSIONS
} from '@/utils/Urls';

export default class AccessControl {

  // users
  static getUsers = () => Api.getResponse(() => http.get(GET_ACL_USERS));

  static updateUser = (id, data) => Api.getResponse(() => http.patch(`${UPDATE_ACL_USER}${id}`, data));

  // groups
  static getGroups = () => Api.getResponse(() => http.get(GET_ACL_GROUPS));

  static createGroup = (data) => Api.getResponse(() => http.post(CREATE_ACL_GROUP, data));

  static updateGroup = (id, data) => Api.getResponse(() => http.patch(`${UPDATE_ACL_GROUP}${id}`, data));

  static destroyGroup = (id) => Api.getResponse(() => http.delete(`${DELETE_ACL_GROUP}${id}`));


  // permissions
  static getPermissions = () => Api.getResponse(() => http.get(GET_ACL_PERMISSIONS));
}
