export default {

  // layout
  getSidebarHover: (state) => state.layout.sidebar.hovered,
  getSidebarExpanded: (state) => state.layout.sidebar.expanded,

  // new order sound
  getNewOrderSoundIsEnabled: (state) => state.newOrder.sound.enabled,
  getNewOrderSoundPlay: (state) => state.newOrder.sound.play,
  getNewOrdersCount: (state) => state.newOrder.count,

  getNewReservationCount: (state) => state.newReservation.count,

};
