import { SET_DATA, SET_DATA_FETCHED, SET_ERROR_MSG, SET_ERRORS, SET_LOADING } from '@/store/modules/default/types';

/**
 * Payload should have path & data key
 * Path holds state path
 * data holds actual payload
 */

export default {
  [SET_LOADING]: (state, payload) => state[payload.path].loading = payload.data,
  [SET_DATA_FETCHED]: (state, payload) => state[payload.path].dataFetched = payload.data,
  [SET_DATA]: (state, payload) => state[payload.path].data = payload.data,
  [SET_ERRORS]: (state, payload) => state[payload.path].errors = payload.data,
  [SET_ERROR_MSG]: (state, payload) => state[payload.path].errorMsg = payload.data,
};

