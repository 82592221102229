import Api from '@/Services/Api';
import http from '@/Plugins/http';
import { AUTH_USER, LOGIN_URL, LOGOUT_URL } from '@/utils/Urls';

export default class Auth {

  static login = (credentials) => Api.getResponse(() => http.post(LOGIN_URL, credentials));

  static logout = () => Api.getResponse(() => http.post(LOGOUT_URL));

  static user = () => Api.getResponse(() => http.get(AUTH_USER));
}
