import defaultState from '../default/state';
import defaultMutations from '../default/mutations';
import actions from '@/store/modules/dashboard/actions';
import getters from '@/store/modules/dashboard/getters';

export default {
  namespaced: true,

  state: () => ({
    ...defaultState,
    data: {
      offerCount: 0,

      orders: [],
      orderStatusOptions: [],
      orderCount: 0,

      reservations: [],
      reservationStatusOptions: [],
    },
  }),

  getters: {
    ...getters,
  },

  mutations: {
    ...defaultMutations,
  },

  actions: {
    ...actions,
  }

};
