import accessControlStore from '@/store/modules/accessControl';
import appStore from '@/store/modules/app';
import customerStore from '@/store/modules/Customers';
import dashboardStore from '@/store/modules/dashboard';
import deliveryChargeStore from '@/store/modules/deliveryCharge';
import menuStore from '@/store/modules/menu';
import offerStore from '@/store/modules/offer';
import openingStore from '@/store/modules/opening';
import orderStore from '@/store/modules/order';
import reservationState from '@/store/modules/reservation';
import seoMetaStore from '@/store/modules/seoMeta';
import settingStore from '@/store/modules/setting';
import userStore from '@/store/modules/user';
import inactivityAd from '@/store/modules/inactivityAd';

const modules = {
  app: appStore,
  user: userStore,
  order: orderStore,
  reservation: reservationState,
  dashboard: dashboardStore,
  menu: menuStore,
  opening: openingStore,
  offer: offerStore,
  inactivityAd: inactivityAd,
  customer: customerStore,
  acl: accessControlStore,
  deliveryCharge: deliveryChargeStore,
  seo: seoMetaStore,
  setting: settingStore,
};

export default modules;
