import defaultState from '../default/state';
import defaultMutations from '../default/mutations';
import actions from './actions';

export default {
  namespaced: true,

  state: () => ({
    ...defaultState,
  }),

  getters: {
    orders: (state) => state.data.orders || [],
    permissions: (state) => state.data.permissions,
    statuses: (state) => state.data.statuses,
  },

  mutations: {
    ...defaultMutations,

    setOrder: (state, payload) => state.data.orders = payload,
  },

  actions: {
    ...actions,
  }
};
