import {
  SET_BOOTSTRAPPED, SET_EXPANDED, SET_HOVERED, SET_NEW_ORDER_COUNT, SET_NEW_RESERVATION_COUNT, SET_SOUND, SET_SOUND_PLAY
} from './types';
import { SET_LOADING } from '@/store/modules/default/types';

export default {
  [SET_LOADING]: (state, payload) => state.loading = payload,
  [SET_BOOTSTRAPPED]: (state, payload) => state.bootstrapped = payload,

  // layout
  [SET_HOVERED]: (state, payload) => state.layout.sidebar.hovered = payload,
  [SET_EXPANDED]: (state, payload) => state.layout.sidebar.expanded = payload,

  // new order
  [SET_SOUND]: (state, payload) => state.newOrder.sound.enabled = payload,
  [SET_SOUND_PLAY]: (state, payload) => state.newOrder.sound.play = payload,
  [SET_NEW_ORDER_COUNT]: (state, payload) => state.newOrder.count = payload,

  [SET_NEW_RESERVATION_COUNT]: (state, payload) => state.newReservation.count = payload,
}
