import { Ability } from '@casl/ability';

import { computed, watch } from 'vue';
import store from '@/store';

let abilities = new Ability([]);

const userPermissions = computed(() => store?.state?.user?.data?.permisssions || []);

const updateUserPermissions = () => {

  const permissions = userPermissions.value || [];

  if (!permissions) return;

  const permissionsWithGroup = permissions.map((permission) => ({
    action: permission
  }));

  abilities.update(permissionsWithGroup);

};

// Watch if user permission { updated | loaded | removed }
watch(userPermissions, () => {
  updateUserPermissions();
}, { deep: true, immediate: true });

export { abilities, userPermissions };
export default abilities;
