import { reactive, computed, toRefs } from 'vue'

import store from '@/store'
import { CURRENCY_SYMBOL, CURRENCY_NAME, APP_LOCALE } from '@/utils/constants';

const appConfig = reactive({
  currencySign: computed(() => store.getters['setting/getSettings']('currency_symbol', true, CURRENCY_SYMBOL)), // Currency Symbol For App `$ || £`)
  currency: computed(() => store.getters['setting/getSettings']('currency', true, CURRENCY_NAME)), // currency Name `GBP || USD`
  locale: computed(() => store.getters['setting/getSettings']('locale', true, APP_LOCALE)), // BCP 47 language tag
});

export default toRefs(appConfig)
