import { LIST_ITEM_ACTION_DELETE, LIST_ITEM_ACTION_UPDATE } from '@/utils/constants';

export const SET_LOADING = 'set_loading';
export const SET_DATA_FETCHED = 'set_data_fetched';
export const SET_DATA = 'set_data';
export const SET_ERRORS = 'set_errors';
export const SET_ERROR_MSG = 'set_error_msg';

// used for modification to list of data in store,
// mostly used by actions
export const DATA_MODIFY_ACTION_ADD = 'add';
export const DATA_MODIFY_ACTION_UPDATE = LIST_ITEM_ACTION_UPDATE;
export const DATA_MODIFY_ACTION_DELETE = LIST_ITEM_ACTION_DELETE;
