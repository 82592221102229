<template>
  <div
    :class="containerClasses"
    :style="spinnerContainerStyle"
  >
    <div
      :class="[spinnerClasses.join(' '), classes]"
      :style="spinnerStyle"
    />

  </div>
</template>

<script>
export default {
  name: 'spinner',
  props: {
    alignment: {
      type: String,
      validator: function (value) {
        return ['left', 'center', 'right'].indexOf(value) !== -1;
      },
      default: 'center'
    },
    type: {
      type: String,
      validator: function (value) {
        const allowed = ['pr', 'se', 'su', 'da', 'wa', 'in', 'li', 'dar'];
        const validation = allowed.includes(value);

        if (!validation) throw new Error(`Allowed types are '${allowed}' got '${value}'`);

        return validation;
      },
      default: 'pr'
    },
    size: {
      type: [Number, String],
      default: 30
    },
    thickness: {
      type: [Number, String],
      default: 15
    },
    containerClasses: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    }
  },

  computed: {
    spinnerClasses: function () {

      const typeMap = {
        pr: 'primary',
        se: 'secondary',
        su: 'success',
        wa: 'warning',
        da: 'danger',
        in: 'info',
        li: 'light',
        dar: 'dark'
      };

      return [
        'spinner-border',
        `text-${typeMap[this.type]}`
      ];
    },

    spinnerContainerStyle: function () {
      return {
        display: 'flex',
        justifyContent: this.alignment
      };
    },

    spinnerStyle: function () {
      return {
        fontSize: `${this.thickness}px`,
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    }
  }
};
</script>
