<template>
  <div class="container-fluid">
    <!-- Row start -->
    <div class="row gutters">
      <div class="col-12">
        <!-- Footer start -->
        <div class="footer">
          Copyright @Snebtaf {{ year }}
        </div>
        <!-- Footer end -->
      </div>
    </div>
    <!-- Row end -->
  </div>
</template>

<script>
export default {
  name: 'AppFooter',

  setup () {
    const year = (new Date()).getFullYear();

    return {
      year,
    };
  }
};
</script>
