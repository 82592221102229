import defaultActions from '../default/actions';
import Reservation from '@/Services/Reservation';

export default {
  ...defaultActions,

  getReservations: async ({ dispatch }) => {

    dispatch('setLoading', true);

    try {

      let response = await Reservation.getReservations();
      dispatch('setData', response.data.data);
      dispatch('setDataFetched', true);

    } catch (e) {

      dispatch('setErrors', e.errors);
      dispatch('setErrorMsg', e.message);

    }

    dispatch('setLoading', false);
  },

  deleteReservation: async ({ commit, dispatch, getters }, id) => {

    try {

      const response = await Reservation.destroy(id);

      const reservations = getters.reservation;
      const updatedReservation = reservations.filter((item) => item.id !== id);

      commit('setReservation', updatedReservation);
      return response;

    } catch (e) {

      dispatch('setErrors', e.errors);
      dispatch('setErrorMsg', e.message);
      return Promise.reject(e);

    }
  },

  updateReservation: async ({ commit, dispatch, getters }, { id, data }) => {

    try {

      const response = await Reservation.update(id, data);

      const updatedReservation = response.data.data;

      const items = [...getters.reservation];
      const updateItem = items.map((item) => item.id !== id ? item : updatedReservation);

      commit('setReservation', updateItem);

      // update dashboard reservations too
      dispatch('dashboard/updateOrAddReservation', updatedReservation, { root: true });

      return response;

    } catch (e) {
      dispatch('setErrors', e.errors);
      dispatch('setErrorMsg', e.message);
      return Promise.reject(e);
    }
  }

};
