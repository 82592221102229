import Api from '@/Services/Api';
import http from '@/Plugins/http';
import {
  GET_MENU_CATEGORIES,
  CREATE_MENU_CATEGORY,
  UPDATE_MENU_CATEGORY,
  DELETE_MENU_CATEGORY,
  GET_MENU_ITEMS,
  CREATE_MENU_ITEM,
  UPDATE_MENU_ITEM,
  DELETE_MENU_ITEM,
  GET_MENU_ADDONS,
  CREATE_MENU_ADDON,
  UPDATE_MENU_ADDON,
  DELETE_MENU_ADDON,
  GET_SET_MENUS,
  CREATE_SET_MENU,
  UPDATE_SET_MENU,
  DELETE_SET_MENU,
} from '@/utils/Urls';

export default class Menu {

  // categories
  static getCategories = () => Api.getResponse(() => http.get(GET_MENU_CATEGORIES));

  static createCategory = (data) => Api.getResponse(() => http.post(CREATE_MENU_CATEGORY, data));

  static updateCategory = (id, data) => Api.getResponse(() => http.patch(`${UPDATE_MENU_CATEGORY}${id}`, data));

  static destroyCategory = (id) => Api.getResponse(() => http.delete(`${DELETE_MENU_CATEGORY}${id}`));

  // items
  static getItems = () => Api.getResponse(() => http.get(GET_MENU_ITEMS));

  static createItem = (data) => Api.getResponse(() => http.post(CREATE_MENU_ITEM, data));

  static updateItem = (id, data) => Api.getResponse(() => http.patch(`${UPDATE_MENU_ITEM}${id}`, data));

  static destroyItem = (id) => Api.getResponse(() => http.delete(`${DELETE_MENU_ITEM}${id}`));

  // addons
  static getAddons = () => Api.getResponse(() => http.get(GET_MENU_ADDONS));

  static createAddon = (data) => Api.getResponse(() => http.post(CREATE_MENU_ADDON, data));

  static updateAddon = (id, data) => Api.getResponse(() => http.patch(`${UPDATE_MENU_ADDON}${id}`, data));

  static destroyAddon = (id) => Api.getResponse(() => http.delete(`${DELETE_MENU_ADDON}${id}`));

  // set menu
  static getSetMenus = () => Api.getResponse(() => http.get(GET_SET_MENUS));

  static createSetMenu = (data) => Api.getResponse(() => http.post(CREATE_SET_MENU, data));

  static updateSetMenu = (id, data) => Api.getResponse(() => http.patch(`${UPDATE_SET_MENU}${id}`, data));

  static destroySetMenu = (id) => Api.getResponse(() => http.delete(`${DELETE_SET_MENU}${id}`));

}
