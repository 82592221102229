import { isAuthorized } from '@/utils/Helper';
import routeMap from '@/utils/routeMap';
import permissions from '@/utils/permissions';
import { computed, watch } from 'vue';

import router from '@/router/index';
import store from '@/store';

export default async function authorized (to, from, next) {

  const isAdminLoginPermitted = validateRequest(permissions.admin_login);

  if (!isAdminLoginPermitted) {
    return next({
      name: routeMap.error.unauthorized.name
    });
  }

  const routePermissions = to.meta.permission;
  const validated = validateRequest(routePermissions);

  if (validated) {
    return next();
  }

  return next({
    name: routeMap.error.unauthorized.name
  });

}

const validateRequest = (permissions) => {
  return isAuthorized(permissions);
};

// watch if user unauthenticated
const authenticated = computed(() => store.state.user.authenticated);

watch(authenticated, async (nv) => {
  if (!nv) {
    await router.push({ name: routeMap.auth.login.name });
  }
});
