import defaultActions from '../default/actions';
import DeliveryCharge from '@/Services/DeliveryCharge';

export default {
  ...defaultActions,

  getDeliveryCharges: async ({ dispatch }) => {
    dispatch('setLoading', true);

    try {

      let response = await DeliveryCharge.all();
      await dispatch('setData', response.data.data);
      await dispatch('setDataFetched', true);

    } catch (e) {
      dispatch('handleErrorResponse', e);
    }

    dispatch('setLoading', false);
  },

  createDeliveryCharge: async ({ commit, dispatch, getters }, data) => {

    try {

      let response = await DeliveryCharge.create(data);

      let stateData = [...getters.getCharges];
      stateData.unshift(response.data.data);

      commit('setDeliveryCharges', stateData);

      return response;
    } catch (e) {

      dispatch('handleErrorResponse', e);

      return Promise.reject(e);
    }
  },

  updateDeliveryCharge: async ({ commit, dispatch, getters }, { id, data }) => {


    try {

      let response = await DeliveryCharge.update(id, data);

      let stateData = [...getters.getCharges];
      const updatedData = stateData.map((item) => item.id !== id ? item : response.data.data);

      commit('setDeliveryCharges', updatedData)

      return response;
    } catch (e) {

      dispatch('handleErrorResponse', e);

      return Promise.reject(e);
    }
  },

  deleteDeliveryCharge: async ({ commit, dispatch, getters }, id) => {

    try {

      let response = await DeliveryCharge.destroy(id);

      const currentData = [...getters.getCharges];
      const updatedData = currentData.filter((item) => item.id !== id);

      commit('setDeliveryCharges', updatedData);

      return response;

    } catch (e) {

      dispatch('setErrors', e.errors);


      return Promise.reject(e);

    }
  },

  handleErrorResponse: ({ dispatch }, e) => {
    dispatch('setErrors', e.errors);
    dispatch('setErrorMsg', e.message);
  }

};
