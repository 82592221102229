<template>
  <ConfirmDialog />
  <router-view/>
</template>

<script>
import ConfirmDialog from 'primevue/confirmdialog';
export default {
  name: 'app',
  components: { ConfirmDialog }
}
</script>
