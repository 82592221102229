import {
  SET_AUTHENTICATED,
  SET_PERMISSIONS, SET_TOKEN_VALIDATED
} from './types';
import * as defaultActions from '../default/actions';
import { appLogout, getFromLocalStorage, pullFromLocalStorage, setToLocalStorage } from '@/utils/Helper';
import { AUTH_TOKEN_NAME } from '@/utils/constants';
import Auth from '@/Services/Auth';
import { SET_DATA } from '@/store/modules/default/types';

export default {
  ...defaultActions.default,
  setPermissions: ({ commit }, payload) => commit(SET_PERMISSIONS, payload),
  setAuthenticated: ({ commit }, payload) => commit(SET_AUTHENTICATED, payload),

  setTokenValidated: async ({ commit, dispatch }) => {

    const token = getFromLocalStorage(AUTH_TOKEN_NAME, false);

    if (!token) return;

    await dispatch('setLoading', true);
    try {

      let response = await Auth.user();
      // get Settings
      await dispatch('setting/fetchAppSettings', null, { root: true });
      commit(SET_DATA, response.data.data);
      dispatch('setAuthenticated', true);
      commit(SET_TOKEN_VALIDATED, true);

    } catch (e) {

      pullFromLocalStorage(AUTH_TOKEN_NAME, false);

      commit(SET_DATA, {});
      commit(SET_TOKEN_VALIDATED, false);

      await dispatch('setAuthenticated', false);
      await dispatch('setErrors', e.errors);
      await dispatch('setErrorMsg', e.message || '');
    }
    await dispatch('setLoading', false);

  },

  tryLogin: async ({ dispatch }, payload) => {

    dispatch('setLoading', true);

    try {

      const response = await Auth.login(payload);
      const { access_token, user } = response.data.data;
      // await dispatch('setting/fetchAppSettings', null, { root: true });

      setToLocalStorage(AUTH_TOKEN_NAME, access_token, false);

      await dispatch('setData', user);
      await dispatch('setAuthenticated', true);
      await dispatch('setTokenValidated', true);

      await dispatch('setLoading', false);
      await dispatch('setDataFetched', true);

    } catch (e) {
      pullFromLocalStorage(AUTH_TOKEN_NAME, false);

      await dispatch('setData', {});
      await dispatch('setAuthenticated', false);
      await dispatch('setErrors', e?.errors);
      await dispatch('setErrorMsg', e.message);

      await dispatch('setLoading', false);
    }

  },

  tryLogOut: async ({ dispatch }) => {

    dispatch('setLoading', true);

    try {

      await Auth.logout();

      pullFromLocalStorage(AUTH_TOKEN_NAME, false);

      await dispatch('setData', {});
      await dispatch('setAuthenticated', false);
      await dispatch('setTokenValidated', false);

      await dispatch('setLoading', false);

      // logout from app
      appLogout();

    } catch (e) {

      await dispatch('setErrors', e?.errors);
      await dispatch('setErrorMsg', e.message);

      await dispatch('setLoading', false);
    }

  },
};
