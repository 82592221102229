import categoryStore from '@/store/modules/menu/categories';
import itemStore from '@/store/modules/menu/items';
import addonStore from '@/store/modules/menu/addons';
import setMenuStore from '@/store/modules/menu/setMenu';

export default {
  namespaced: true,

  state: () => ({
    categories: { ...categoryStore.state },
    items: { ...itemStore.state },
    addons: { ...addonStore.state },
    setMenu: { ...setMenuStore.state },
  }),

  mutations: {
    ...categoryStore.mutations,
    ...itemStore.mutations,
    ...addonStore.mutations,
    ...setMenuStore.mutations,
  },

  actions: {
    ...categoryStore.actions,
    ...itemStore.actions,
    ...addonStore.actions,
    ...setMenuStore.actions,
  }

};
