import defaultState from '../default/state';
import defaultMutations from '../default/mutations';
import actions from './actions';

export default {
  namespaced: true,

  state: () => ({
    ...defaultState,
  }),

  getters: {
    ads: (state) => state.data,
  },

  mutations: {
    ...defaultMutations,
  },

  actions: {
    ...actions,
  }
};
