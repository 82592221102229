import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import store from '@/store';
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css';
import { isMobile } from 'mobile-device-detect';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeResolve((to, from, next) => {
  Nprogress.start();
  next()
})

router.afterEach(() => {
  Nprogress.done();

  // hide side bar when navigating to other page in mobile device
  if (isMobile) {
    store.dispatch('app/setSidebarExpanded', false);
  }

})

export default router;
