import Api from '@/Services/Api';
import http from '@/Plugins/http';
import { CREATE_OFFER, DELETE_OFFER, GET_OFFER, UPDATE_OFFER, } from '@/utils/Urls';

export default class Offer {
  static getOffer = () => Api.getResponse(() => http.get(GET_OFFER));

  static create = (data) => Api.getResponse(() => http.post(CREATE_OFFER, data));

  static update = (id, data) => Api.getResponse(() => http.patch(`${UPDATE_OFFER}/${id}`, data));

  static destroy = (id) => Api.getResponse(() => http.delete(`${DELETE_OFFER}/${id}`));
}





