import Store from '@/store/index';
import routeMap from '@/utils/routeMap';

export default async function (to, from, next) {

  const validated = await validateRequest();

  if (validated) {
    return next();
  }

  return next({
    name: routeMap.auth.login.name,
    query: { redirect: to.fullPath }
  });
}

const validateRequest = async () => {

  let bootstrapped = Store.state.app.bootstrapped;
  let authenticated = Store.state.user.authenticated;
  let tokenValidated = Store.state.user.tokenValidated;

  if (bootstrapped && tokenValidated) {
    return authenticated;
  }

  await Store.dispatch('app/setBootstrap', true);

  authenticated = Store.state.user.authenticated;

  return authenticated;
};

