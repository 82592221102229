import Api from '@/Services/Api';
import http from '@/Plugins/http';
import {
  GET_INACTIVITY_AD,
  CREATE_INACTIVITY_AD,
  UPDATE_INACTIVITY_AD,
  DELETE_INACTIVITY_AD
} from '@/utils/Urls';
import { axiosDefaultFormDataHeader } from '@/utils/Helper';

export default class InactivityAd {
  static fetchAll = () => Api.getResponse(() => http.get(GET_INACTIVITY_AD));

  static create = (data) => Api.getResponse(() => {

    if (!(data instanceof FormData)) {
      throw new Error("'Data' must be of type FormData");
    }

    return http.post(CREATE_INACTIVITY_AD, data, axiosDefaultFormDataHeader);

  });

  static update = (id, data) => Api.getResponse(() => {

    if (!(data instanceof FormData)) {
      throw new Error("'Data' must be of type FormData");
    }

    //add patch method, this is required for laravel/php
    data.append('_method', 'patch');

    return http.post(`${UPDATE_INACTIVITY_AD}${id}`, data, axiosDefaultFormDataHeader);

  });

  static destroy = (id) => Api.getResponse(() => http.delete(`${DELETE_INACTIVITY_AD}${id}`));
}





