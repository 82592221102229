import Api from '@/Services/Api';
import http from '@/Plugins/http';
import { CREATE_OPENING_HOURS, DELETE_OPENING_HOURS, GET_OPENING_HOURS, UPDATE_OPENING_HOURS } from '@/utils/Urls';

export default class Opening {
  static getOpenings = () => Api.getResponse(() => http.get(GET_OPENING_HOURS));

  static create = (data) => Api.getResponse(() => http.post(CREATE_OPENING_HOURS, data));

  static update = (id, data) => Api.getResponse(() => http.patch(`${UPDATE_OPENING_HOURS}/${id}`, data));

  static destroy = (id) => Api.getResponse(() => http.delete(`${DELETE_OPENING_HOURS}/${id}`));
}
