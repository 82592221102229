import Api from '@/Services/Api';
import http from '@/Plugins/http';
import { DELETE_RESERVATION, GET_RESERVATIONS, UPDATE_RESERVATION } from '@/utils/Urls';

export default class Reservation {

  static getReservations = () => Api.getResponse(() => http.get(GET_RESERVATIONS));

  static update = (id, data) => Api.getResponse(() => http.patch(`${UPDATE_RESERVATION}/${id}`, data));

  static destroy = (id) => Api.getResponse(() => http.delete(`${DELETE_RESERVATION}/${id}`));

}
