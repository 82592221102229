import defaultActions from '../default/actions';
import Offer from '@/Services/Offer';

export default {
  ...defaultActions,

  /**
   * @return {Promise<void>}
   */
  getOffer: async ({ dispatch }) => {
    dispatch('setLoading', true);

    try {

      let response = await Offer.getOffer();
      await dispatch('setData', response.data.data);
      await dispatch('setDataFetched', true);

    } catch (e) {

      dispatch('setErrors', e.errors);
      dispatch('setErrorMsg', e.message);

    }

    dispatch('setLoading', false);
  },

  createOffer: async ({ commit, dispatch, getters }, data) => {

    try {
      const response = await Offer.create(data);

      // Get Current items
      let offers = [...getters.offer];

      // push newly added item to store
      offers.unshift(response.data.data);

      // override items to store
       commit('setOffer', offers);
      return response;

    } catch (e) {

      dispatch('setErrors', e.errors);
      dispatch('setErrorMsg', e.message);
      return Promise.reject(e);

    }
  },

  UpdateOffer: async ({ commit, dispatch, getters }, { data, id }) => {

    try {
      const response = await Offer.update(id, data);

      // Get Current items
      let offers = [...getters.offer];

      // push updated item to store
      const updatedOffer = offers.map((item) => item.id !== id ? item : response.data.data)

      // override items to store
      commit('setOffer', updatedOffer);
      return response;

    } catch (e) {

      dispatch('setErrors', e.errors);
      dispatch('setErrorMsg', e.message);
      return Promise.reject(e);

    }
  },

  deleteOffer: async ({ getters, commit, dispatch }, id) => {

    try {

      let response = await Offer.destroy(id);
      const collection = getters.offer.filter((item) => item.id !== id);
      commit('setOffer', collection);
      return response;

    } catch (e) {

      dispatch('setErrors', e.errors);
      dispatch('setErrorMsg', e.message);
      return Promise.reject(e);

    }
  }
};
