import defaultState from '../default/state';
import defaultMutations from '../default/mutations';
import actions from './actions';

export default {
  namespaced: true,

  state: () => ({
    ...defaultState,
  }),

  getters: {
    seoMetas: (state) => state?.data?.allSeo || []
  },

  mutations: {
    ...defaultMutations,
    setSeoMeta: (state, payload) => state.data.allSeo = payload,
  },

  actions: {
    ...actions,
  }

};
