import AccessControl from '@/Services/AccessControl';
import {
  SET_DATA, SET_DATA_FETCHED,
  SET_ERROR_MSG,
  SET_ERRORS,
  SET_LOADING
} from '@/store/modules/default/types';

const statePath = 'permissions';

export default {

  setPermissionsLoading: ({ commit }, payload) => commit(SET_LOADING, { path: statePath, data: payload }),
  setPermissionsDataFetched: ({ commit }, payload) => commit(SET_DATA_FETCHED, { path: statePath, data: payload }),
  setPermissionsData: ({ commit }, payload) => commit(SET_DATA, { path: statePath, data: payload }),
  setPermissionsErrors: ({ commit }, payload) => commit(SET_ERRORS, { path: statePath, data: payload }),
  setPermissionsErrorMsg: ({ commit }, payload) => commit(SET_ERROR_MSG, { path: statePath, data: payload }),


  fetchPermissions: async ({ dispatch }) => {

    dispatch('setPermissionsLoading', true);

    try {

      const response = await AccessControl.getPermissions();
      const { data } = response.data;

      await dispatch('setPermissionsData', data);
      await dispatch('setPermissionsDataFetched', true);

    } catch (e) {

      await dispatch('setPermissionsData', {});
      await dispatch('setPermissionsErrors', e?.errors);
      await dispatch('setPermissionsErrorMsg', e.message);

    }

    dispatch('setPermissionsLoading', false);

  }

};
