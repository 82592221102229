import defaultActions from '../default/actions';
import Customers from '@/Services/Customers';

export default {
  ...defaultActions,

  getCustomers: async ({ dispatch }) => {
    dispatch('setLoading', true);

    try {

      let response = await Customers.getCustomers();
      dispatch('setData', response.data.data);
      dispatch('setDataFetched', true);

    } catch (e) {

      dispatch('setErrors', e.errors);
      dispatch('setErrorMsg', e.message);

    }

    dispatch('setLoading', false);
  }

};
