import Api from '@/Services/Api';
import http from '@/Plugins/http';
import { CREATE_SEO_META, DELETE_SEO_META, GET_SEO_META, UPDATE_SEO_META, } from '@/utils/Urls';

const requestConfig = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
};

export default class Seo {
  static all = () => Api.getResponse(() => http.get(GET_SEO_META));

  static create = (data) => Api.getResponse(() => http.post(CREATE_SEO_META, data));

  static update = (id, data) => {

    if (data instanceof FormData) {
      data.append('_method', 'PATCH');
    } else {
      data._method = 'PATCH';
    }

    return Api.getResponse(() => http.post(`${UPDATE_SEO_META}/${id}`, data, requestConfig));
  };

  static delete = (id) => Api.getResponse(() => http.delete(`${DELETE_SEO_META}/${id}`));
}
