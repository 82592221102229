import userStore from '@/store/modules/accessControl/users';
import groupStore from '@/store/modules/accessControl/groups';
import permissionStore from '@/store/modules/accessControl/permissions';

export default {
  namespaced: true,

  state: () => ({
    users: { ...userStore.state },
    groups: { ...groupStore.state },
    permissions: { ...permissionStore.state },
  }),

  mutations: {
    ...userStore.mutations,
    ...groupStore.mutations,
    ...permissionStore.mutations,
  },

  actions: {
    ...userStore.actions,
    ...groupStore.actions,
    ...permissionStore.actions,
  }

};
