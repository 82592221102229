import Echo from 'laravel-echo';

// don't delete this
// eslint-disable-next-line no-unused-vars
import Pusher from 'pusher-js';

import http from '@/Plugins/http';
import { WS_AUTHORIZATION } from '@/utils/Urls';

// Singleton
const WS = (function () {

  let instance;

  function createInstance () {

    return new Echo({
      broadcaster: 'pusher',
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      key: process.env.VUE_APP_PUSHER_APP_KEY,
      encrypted: true,
      forceTLS: true,
      authorizer: (channel, options) => {

        return {
          authorize: (socketId, callback) => {
            http
              .post(WS_AUTHORIZATION, {
                socket_id: socketId,
                channel_name: channel.name
              })

              // eslint-disable-next-line standard/no-callback-literal
              .then(response => callback(false, response.data))

              // eslint-disable-next-line standard/no-callback-literal
              .catch(error => callback(true, error))
            ;
          }
        };

      },
    });
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }

      return instance;
    }
  };
})();

export default WS;
