<template>
  <div
    v-if="slideData.type === 'img'"
    class="d-flex align-items-center justify-content-center h-100 w-100"
  >
    <img
      class="img img-fluid"
      :src="mediaSource"
      :alt="`slide-${slideData.id}`"
    />
  </div>

  <vue-plyr
    v-else
    ref="videoPlayer"
    :options="state.playerOptions"
  >
    <div class="plyr__video-embed">
      <iframe
        :src="mediaSource"
        allowfullscreen
        allowtransparency
        allow="autoplay"
      />
    </div>
  </vue-plyr>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue';
import 'vue-plyr/dist/vue-plyr.css';
import { STORAGE_FULL_URL } from '@/utils/Urls';

export default {
  name: 'InactivityAdItem',
  props: {
    slideData: {
      type: Object,
      required: true
    },
    currentSlideShown: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {

    const siteUrl = window.location.origin;
    const videoPlayer = ref(null);
    const isVideo = computed(() => props.slideData?.type === 'video');

    const youtubeVideoParams = {
      autoplay: 0,
      loop: 1,
      fs: 0,
      iv_load_policy: 3,
      showinfo: 0,
      rel: 0,
      cc_load_policy: 0,
      start: 0,
      end: 0,
      theme: 'dark',
      color: 'white',
      autohide: 1,
      keyboard: 1,
      controls: 0,
      modestbranding: 1,
      playsinline: 1,
      enablejsapi: 1,
      origin: siteUrl,
    };

    const state = reactive({
      playerOptions: {
        autoplay: false,
        muted: props.slideData.with_sound,
        controls: [],
        clickToPlay: false,
        resetOnEnd: true,
        displayDuration: false,
        volume: 0.7,
        youtube: {
          noCookie: false,
          rel: 0,
          showinfo: 0,
          iv_load_policy: 3,
          modestbranding: 1,
          origin: siteUrl
        }
      }
    });

    const mediaSource = computed(() => {

      const source = props.slideData.media;

      if (!isVideo.value) return `${STORAGE_FULL_URL}inactivityAd/${source}`;

      return getYTVideoWithParams(source);
    });

    function getYTVideoWithParams (youtubeVideoId) {
      let ytParams = '';

      const parameters = Object.keys(youtubeVideoParams);

      parameters.map((paramName, index) => {
        ytParams += `${paramName}=${youtubeVideoParams[paramName]}`;

        // add & to add multiple params when not the last item
        if ((index + 1) < parameters.length) {
          ytParams += '&';
        }
      });

      // join params with link
      return `https://www.youtube.com/embed/${youtubeVideoId}?${ytParams}`;
    }

    function playVideo () {
      const player = videoPlayer.value?.player;
      if (player) {
        player.play();
      }
    }

    function stopVideo () {
      const player = videoPlayer.value?.player;

      if (player) {
        player.stop();
      }
    }

    watch(() => props.currentSlideShown, (nv) => {

      if (!isVideo.value) return;

      if (nv) {
        playVideo();
        return;
      }

      stopVideo();
    });

    onMounted(() => {
      const player = videoPlayer.value?.player;

      setTimeout(() => {
        // if already not playing & type video
        if (!player.playing && props.slideData.type === 'video') {
          playVideo();
        }
      }, 1000);
    });

    return {
      state,
      mediaSource,
      videoPlayer
    };
  }
};
</script>

<style scoped>

</style>
