import { SET_DATA, SET_DATA_FETCHED, SET_ERROR_MSG, SET_ERRORS, SET_LOADING } from '@/store/modules/default/types';

export default {
  [SET_LOADING]: (state, payload) => state.loading = payload,
  [SET_DATA_FETCHED]: (state, payload) => state.dataFetched = payload,
  [SET_DATA]: (state, payload) => state.data = payload,
  [SET_ERRORS]: (state, payload) => state.errors = payload,
  [SET_ERROR_MSG]: (state, payload) => state.errorMsg = payload,
};

