import Menu from '@/Services/Menu';
import {
  DATA_MODIFY_ACTION_ADD, DATA_MODIFY_ACTION_DELETE, DATA_MODIFY_ACTION_UPDATE,
  SET_DATA, SET_DATA_FETCHED,
  SET_ERROR_MSG,
  SET_ERRORS,
  SET_LOADING
} from '@/store/modules/default/types';
import { touchCollection } from '@/utils/Helper';

const statePath = 'categories';

export default {

  setCategoryDataFetched: ({ commit }, payload) => commit(SET_DATA_FETCHED, { path: statePath, data: payload }),
  setCategoryLoading: ({ commit }, payload) => commit(SET_LOADING, { path: statePath, data: payload }),
  setCategoryData: ({ commit }, payload) => commit(SET_DATA, { path: statePath, data: payload }),
  setCategoryErrors: ({ commit }, payload) => commit(SET_ERRORS, { path: statePath, data: payload }),
  setCategoryErrorMsg: ({ commit }, payload) => commit(SET_ERROR_MSG, { path: statePath, data: payload }),

  fetchCategories: async ({ dispatch }) => {

    dispatch('setCategoryLoading', true);

    try {

      const response = await Menu.getCategories();
      const { data } = response.data;

      await dispatch('setCategoryData', data);
      await dispatch('setCategoryDataFetched', true);

    } catch (e) {

      await dispatch('setCategoryData', {});
      await dispatch('setCategoryErrors', e?.errors);
      await dispatch('setCategoryErrorMsg', e.message);

    }

    dispatch('setCategoryLoading', false);

  },

  createCategory: async ({ dispatch, state }, payload) => {

    const response = await Menu.createCategory(payload);
    const { data } = response.data;

    const newCategories = {
      ...state.categories.data,
      categories: touchCollection(state.categories.data.categories, data, DATA_MODIFY_ACTION_ADD)
    };

    await dispatch('setCategoryData', newCategories);

    return data;
  },

  updateCategory: async ({ dispatch, state }, { id, data }) => {

    const response = await Menu.updateCategory(id, data);
    const { data: result } = response.data;

    const newCategories = {
      ...state.categories.data,
      categories: touchCollection(state.categories.data.categories, result, DATA_MODIFY_ACTION_UPDATE)
    };

    await dispatch('setCategoryData', newCategories);

    return data;
  },

  deleteCategory: async ({ dispatch, state }, payload) => {

    try {
      const response = await Menu.destroyCategory(payload);
      const { data } = response.data;

      const newCategories = {
        ...state.categories.data,
        categories: touchCollection(state.categories.data.categories, payload, DATA_MODIFY_ACTION_DELETE)
      };

      await dispatch('setCategoryData', newCategories);

      return data;
    } catch (e) {
      console.log(e);
      return Promise.reject(e)
    }
  },

};
