import {
  SET_PERMISSIONS,
  SET_AUTHENTICATED,
  SET_TOKEN_VALIDATED
} from './types'
import * as defaultMutation from '../default/mutations'

export default {
  ...defaultMutation.default,
  [SET_PERMISSIONS]: (state, payload) => state.permissions = payload,
  [SET_AUTHENTICATED]: (state, payload) => state.authenticated = payload,
  [SET_TOKEN_VALIDATED]: (state, payload) => state.tokenValidated = payload,
}
