<template>
  <li
    :class="{[routeIs(routeName)]: !subRoute}"
  >

    <router-link
      :to="{name: routeName}"
    >
      <i
        v-if="!subRoute && icon"
        :class="icon"
      />

      <!--only use element if not sub route-->
      <span
        :class="{
          'menu-text': !subRoute,
          'text-primary': (subRoute && routeIs(routeName).length)
        }"
      >
        {{ title || routeName }}
      </span>

    </router-link>

  </li>
</template>

<script>
import { routeIs } from '@/utils/Helper';

export default {
  name: 'MenuItem',
  props: {
    icon: {
      type: String,
      default: ''
    },
    routeName: {
      type: String,
    },
    title: {
      type: String,
    },
    subRoute: {
      type: Boolean,
      default: false
    }
  },

  setup () {

    return {
      routeIs
    };
  }
};
</script>

<style scoped>

</style>
