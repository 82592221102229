import { reactive, toRefs } from 'vue';
import {
  CURRENCY_SYMBOL,
  DATETIME_FORMAT_1,
  DEFAULT_DELETE_HEADER,
  DEFAULT_DELETE_MESSAGE,
  DEFAULT_DELETE_TYPE,
  APP_TIMEZONE
} from '@/utils/constants';
import { formatISODate } from '@/utils/Helper';
import { ElMessage, ElMessageBox } from 'element-plus';

import appConfig from '@/config/app';

export const currencySymbol = CURRENCY_SYMBOL;

export const userAvatar = (name) => {
  // :TODO Remove Dependency and make generic component
  let userName = name.replaceAll(' ', '+').toLowerCase();
  return `https://ui-avatars.com/api/bold=true??background=ff1e1e?name=${userName}`;
};

/**
 *
 * @param status
 * @return {string}
 */
export function getBadgeClass (status) {
  let badgeClass = 'badge-danger';

  switch (status) {

    case 'disabled':
    case 'pending':
      badgeClass = 'badge-dark';
      break;

    case 'on the way':
    case 'delivering':
      badgeClass = 'badge-info';
      break;

    case 'accepted':
    case 'processing':
    case 'shown':
    case 'enabled':
      badgeClass = 'badge-primary';
      break;

    case 1:
    case 'delivered':
    case 'collected':
    case 'paid' :
    case 'confirmed' :
      badgeClass = 'badge-success';
      break;

    case 0:
    case 'hidden':
    case 'due':
    case 'cancelled':
      badgeClass = 'badge-secondary';
      break;

    default:
      if (typeof status === 'boolean') {
        badgeClass = status ? 'badge-success' : 'badge-danger'
      }
      break;
  }

  return badgeClass;
}

/**
 *
 * @param timeString
 * @param format
 * @return {String | void}
 */
export const dateFormat = (timeString, format = DATETIME_FORMAT_1) => {
  if (!timeString) return;
  return formatISODate(timeString, format, APP_TIMEZONE);
};

/**
 *
 * @param amount {Number}
 * @return { string | void}
 */
export const currency = (amount) => {

  let { currency, locale } = appConfig;

  let formatter = new Intl.NumberFormat(locale.value, {
    style: 'currency',
    currency: currency.value,
  });

  return formatter.format(parseFloat(amount) || 0);

  // return `${CURRENCY_SYMBOL}${parseFloat(amount || 0).toFixed(2)}`;
};

/**
 *
 * @param item
 * @param classes {string}
 * @return {string | HTML}
 */
export const renderStatus = (item, classes = '') => {
  let itemLabel = item;
  let badgeClass = getBadgeClass(item);

  return `<span class="badge table-badge ${badgeClass} badge-pill text-capitalize order-action ${classes}">${itemLabel}</span>`;
};

/**
 *
 * @param message {string} message
 * @param type {string} success/warning/info/error
 * @param duration {Number} 3000
 * @param showClose {Boolean} true
 * @return void
 */
export const notify = (message = 'Something Happened', type = 'info', duration = 2000, showClose = true) => {

  let notificationType;

  switch (type) {

    case 'info':
      notificationType = 'info';
      break;

    case 'success':
      notificationType = 'success';
      break;

    case 'warning':
      notificationType = 'warning';
      break;

    case 'error':
      notificationType = 'error';
      break;

    default:
      notificationType = 'info';
      break;

  }

  ElMessage({
    message: message,
    type: notificationType,
    showClose: showClose,
    duration: duration,
  });
};

/**
 *
 * @param title
 * @param message
 * @param type
 * @param successCallback
 * @param cancelCallback
 * @param confirmButtonText
 * @param cancelButtonText
 * @return void
 */
export const msgBox = ({
  title = DEFAULT_DELETE_HEADER,
  message = DEFAULT_DELETE_MESSAGE,
  type = DEFAULT_DELETE_TYPE,
  successCallback,
  cancelCallback = null,
  confirmButtonText = 'Delete',
  cancelButtonText = 'Cancel'
}) => {

  ElMessageBox.confirm(
    message,
    title,
    {
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      type: type,
      confirmButtonClass: 'text-dark',

    }
  ).then(() => {

    successCallback();

  }).catch(() => {

    if (typeof cancelCallback === 'function') {
      cancelCallback();
    }

  });
};
