import defaultActions from '../default/actions';
import Settings from '@/Services/Settings';
import { touchCollection } from '@/utils/Helper';
import {
  DATA_MODIFY_ACTION_ADD,
  DATA_MODIFY_ACTION_DELETE,
  DATA_MODIFY_ACTION_UPDATE
} from '@/store/modules/default/types';
import { collect } from 'collect.js';
import { cloneDeep } from 'lodash';

export default {
  ...defaultActions,

  fetchAppSettings: async ({ dispatch }) => {
    dispatch('setLoading', true);

    try {

      let response = await Settings.getSettings();
      await dispatch('setData', response.data.data);
      await dispatch('setDataFetched', true);

    } catch (e) {

      dispatch('setErrors', e.errors);
      dispatch('setErrorMsg', e.message);

    }

    dispatch('setLoading', false);
  },

  createSetting: async ({ dispatch, state }, payload) => {

    const response = await Settings.createSetting(payload);

    const { data } = response.data;

    const newSettings = {
      ...state.data,
      settings: touchSetting(state.data.settings, data, DATA_MODIFY_ACTION_ADD)
    };

    await dispatch('setData', newSettings);

    return data;
  },

  updateSetting: async ({ dispatch, state }, { id, data }) => {

    const response = await Settings.updateSetting(id, data);

    const { data: result } = response.data;

    const newSettings = {
      ...state.data,
      settings: touchSetting(state.data.settings, result, DATA_MODIFY_ACTION_UPDATE)
    };

    await dispatch('setData', newSettings);

    return result;
  },

  updatePromotionalBannerSettings: async ({ dispatch, state }, payload) => {

    // converting JS object to form data
    const formData = new FormData();
    Object.keys(payload).map(settingKey => formData.append(settingKey, payload[settingKey]));

    const response = await Settings.updatePromotionalBannerSettings(formData);

    const { data: results } = response.data;

    const newSettings = {
      ...state.data,
      // settings: touchSetting(state.data.settings, results, DATA_MODIFY_ACTION_UPDATE)
      settings: touchBulkSettings(state.data.settings, results, DATA_MODIFY_ACTION_UPDATE)
    };

    await dispatch('setData', newSettings);

    return response;
  },

  deleteSetting: async ({ dispatch, state }, payload) => {

    const response = await Settings.destroySetting(payload.id);
    const { data } = response.data;

    const newSettings = {
      ...state.data,
      settings: touchSetting(state.data.settings, payload, DATA_MODIFY_ACTION_DELETE)
    };

    await dispatch('setData', newSettings);

    return data;
  },

  updateDelayTimeSetting: async ({ dispatch, state }, payload) => {

    try {

      const response = await Settings.updateDelayTiming(payload);

      const collectionDelayTiming = response.data.data.collection_delay_time;
      const deliveryDelayTiming = response.data.data.delivery_delay_time;

      const updatedSettings = {
        ...state.data,
        // Update collection Timing
        settings: touchSetting(state.data.settings, collectionDelayTiming, DATA_MODIFY_ACTION_UPDATE)
      };

      // update Delivery Timing collection
      updatedSettings.settings = touchSetting(updatedSettings.settings, deliveryDelayTiming, DATA_MODIFY_ACTION_UPDATE);

      await dispatch('setData', updatedSettings);

      return response;

    } catch (e) {
      return Promise.reject(e);
    }

  }

};

/**
 * @param settings : Object
 * @param setting : Object
 * @param actionType : String
 * @return {*}
 */
function touchSetting (settings, setting, actionType) {

  const groupInSetting = settings[setting.group] || [];

  let result = { ...settings };

  switch (actionType) {

    case DATA_MODIFY_ACTION_UPDATE:
      result = prepareDataForUpdate(result, groupInSetting, setting, actionType);
      break;

    case DATA_MODIFY_ACTION_DELETE:
      result = {
        ...result,
        [setting.group]: touchCollection(groupInSetting, setting.id, actionType)
      };
      break;

    // create
    default:
      result = {
        ...result,
        [setting.group]: touchCollection(groupInSetting, setting, actionType)
      };
      break;
  }

  return result;
}

function prepareDataForUpdate (allSettings, groupSettings, setting, actionType) {

  const allSettingCollection = collect(allSettings).flatten(1);

  // check if group is changed
  const realSetting = allSettingCollection.first(item1 => item1.id === setting.id);
  const groupChanged = (realSetting.group !== setting.group);

  // if not then just replace item with updated one
  if (!groupChanged) {
    return {
      ...allSettings,
      [setting.group]: touchCollection(groupSettings, setting, actionType)
    };
  }

  // remove real item
  const allSettingsExcludingRealItem = allSettingCollection
    .filter(item => item.id !== setting.id)
    .mapToGroups(itm => [itm.group, itm])
    .all()
  ;

  return {
    ...allSettingsExcludingRealItem,

    // group with added item
    [setting.group]: [
      ...groupSettings,
      setting
    ]
  };
}

function touchBulkSettings (existingSettings, newSettings, actionType) {

  // copy existing setting so state settings
  let copiedExistingSettings = cloneDeep(existingSettings);

  // modify setting one by one
  Object.keys(newSettings).map(settingKey => {
    copiedExistingSettings = touchSetting(copiedExistingSettings, newSettings[settingKey], actionType);
  });

  return copiedExistingSettings;
}
