// import axios from 'axios';
import {
  DATA_MODIFY_ACTION_ADD,
  DATA_MODIFY_ACTION_DELETE,
  DATA_MODIFY_ACTION_UPDATE
} from '@/store/modules/default/types';
import * as dateFns from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';
import { useRoute } from 'vue-router';
import { APP_TIMEZONE, TIME_SELECTS_FORMAT } from './constants';
import { BASE_API_URL } from '@/utils/Urls';
import _ from 'lodash';

import abilities from '@/config/permissions/ability';
import { renderStatus } from '@/Mixins/appHelper'

export const DateFns = dateFns;
export const DateFnsTz = dateFnsTz;

export const formatModalId = (id, prefix = 'mid') => `${prefix}${id}`;

export const getPermissionSubject = permissionName => {
  if (typeof permissionName !== 'string') throw new Error('Can\'t get permission subject while permission name is not string');

  const separator = '-';
  return permissionName.substr(0, permissionName.indexOf(separator));
};

/**
 * Use when the time string is in ISO format or want to change timezone
 * @param  timeString : String
 * @param [format=do MMM, yyyy] : String
 * @param [timeZone=null] : String
 * @returns String
 */
export const formatISODate = (timeString, format = 'do MMM, yyyy', timeZone = null) => {
  let timeDate = dateFns.parseJSON(timeString);

  if (timeZone !== null) {
    timeDate = DateFnsTz.utcToZonedTime(timeDate, timeZone);
  }

  return dateFns.format(timeDate, format);
};

/**
 * Use when the time string is complex or you want to pass its format for guaranteed accuracy
 * @param  timeString : String
 * @param [format=do MMM, yyyy] : String
 * @param [timeStringFormat=null] : String
 * @returns String
 */
export const formatDate = (timeString, format = 'do MMM, yyyy', timeStringFormat = null) => {
  const date = (timeStringFormat === null)
    ? new Date(timeString)
    : DateFns.parse(timeString, timeStringFormat, new Date())
  ;

  return dateFns.format(date, format);
};

/**
 * Apply timezone to a datetime string or date object
 * @param datetime : String|Date
 * @param [timezone=APP_TIMEZONE] : String - app's timezone set in env
 * @returns Date
 */
export const applyTimezone = (datetime, timezone = APP_TIMEZONE) => {
  const date = (typeof datetime === 'string')
    ? new Date(datetime)
    : datetime
  ;

  return DateFnsTz.utcToZonedTime(date, timezone);
};

export const formatTimePickerCompatibleTime = (timeString) => {
  const timeStr = makeDateTimeFromSQLTime(timeString);
  return formatDate(timeStr.toISOString(), TIME_SELECTS_FORMAT);
};

/**
 *
 * @param timeString
 * @return {Date} - get default timezone applied Date
 */
export const getDate = (timeString = null) => {

  if (timeString) return applyTimezone(timeString);

  return applyTimezone(new Date());

};

export const userAvatarChar = (userName) => {
  if (!userName.length) return '';

  function getCharacter (userName) {
    const nameSplit = userName.split(' ');

    let chars = '';
    if (nameSplit.length === 1) {
      chars += nameSplit[0][0]; // first char
      chars += nameSplit[nameSplit.length - 1][1]; // last char
      return chars;
    }

    chars += nameSplit[0][0]; // first char of first element
    chars += nameSplit[nameSplit.length - 1][0]; // first char of last element

    return chars;
  }

  const character = getCharacter(userName);
  return character.toUpperCase();

};

export const getFromLocalStorage = (key, decode = true) => {
  const value = localStorage.getItem(key);

  return decode
    ? JSON.parse(value)
    : value
    ;
};

export const pullFromLocalStorage = (key, decode = true) => {
  const value = getFromLocalStorage(key, decode);

  localStorage.removeItem(key);

  return value;
};

export const setToLocalStorage = (key, value, encode = true) => {
  const finalValue = encode
    ? JSON.stringify(value)
    : value
  ;

  localStorage.setItem(key, finalValue);

  return finalValue;
};

export const storeHelper = (nameSpace, state) => `${nameSpace}/${state}`;

/**
 *
 * @param routeNames : array|string
 * @param returnValue : string
 * @return string
 */
export const routeIs = (routeNames, returnValue = 'active') => {

  const route = useRoute();

  const currentRouteName = route.name;

  if (typeof routeNames === 'string') {
    return currentRouteName === routeNames ? returnValue : '';
  }

  return routeNames.includes(currentRouteName) ? returnValue : '';
};

/**
 *
 * @param timeString
 * @return { String }
 */
export const formatTime = (timeString) => {
  const dTime = makeDateTimeFromSQLTime(timeString);
  return formatDate(dTime.toISOString(), TIME_SELECTS_FORMAT);
};

export const makeDateTimeFromSQLTime = (sqlTimeString) => {
  const dStr = (new Date()).toDateString();
  return new Date(`${dStr} ${sqlTimeString}`);
};

/**
 *
 * @param obj {Object}
 * @return {boolean | void}
 */
export const isEmptyObject = (obj) => {

  if (typeof obj !== 'object') return;

  return Object.keys(obj).length === 0;
};

/* * Modifies collection (array) based on @type
 * @param collectionState : Array
 * @param value : any
 * @param type : String
 * @return Array
 */
export const touchCollection = (collectionState, payload, type) => {

  const existingItems = [...collectionState];

  let existingIndex;

  switch (type) {
    case DATA_MODIFY_ACTION_ADD:
      return [payload, ...existingItems];

    case DATA_MODIFY_ACTION_UPDATE:
      existingIndex = existingItems.findIndex(itm => itm.id === payload.id);
      existingItems.splice(existingIndex, 1, payload);
      return existingItems;

    case DATA_MODIFY_ACTION_DELETE:
      existingIndex = existingItems.findIndex(itm => itm.id === payload);
      existingItems.splice(existingIndex, 1);
      return existingItems;

    default:
      return existingItems;
  }
};

export const getImage = (img, path) => `${BASE_API_URL}/storage/${path}/${img}`;

export const slugify = (text) => {
  return text
    .toString()
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-');
};

export const fetchDataIfNotExist = (statePath, fetchCb, mapCb) => {
  if (_.isEmpty(statePath)) {

    if (typeof fetchCb !== 'function') return;
    fetchCb();

  } else {

    if (typeof mapCb !== 'function') return;
    mapCb();

  }
};

/**
 * Check user is authorized or not for the current action
 * @param permission {string | array}
 * @param requireAll {Boolean = false}
 * @return {boolean}
 * @example
 * user must have to get all permissions
 *      isAuthorized(['can_view', 'can_update'], true)
 *
 * it will pass if user have at least one permissions
 *      isAuthorized(['can_view', 'can_update'])
 *
 * if it a single permissions to check
 *      isAuthorized('can_view')
 */

export const isAuthorized = (permission, requireAll = false) => {

  let requiredPermission = [];

  if (typeof permission === 'string') {
    return abilities.can(permission);
  }

  if (!Array.isArray(permission)) {
    return false;
  }

  requiredPermission = [...permission];

  let isPermitted = false;

  if (!requiredPermission) return isPermitted;

  if (requireAll) {

    let isPermitted = true;

    requiredPermission.forEach((userPermission) => {

      if (abilities.cannot(userPermission)) {

        isPermitted = false;

        return isPermitted;
      }

    });

    return isPermitted;
  }

  requiredPermission.forEach((userPermission) => {

    if (abilities.can(userPermission)) {

      isPermitted = true;

      return isPermitted;
    }

  });

  return isPermitted;

};

export const reservationStatuses = {
  pending: 'pending',
  confirmed: 'confirmed',
  cancelled: 'cancelled',
};

export const orderTypes = {
  collection: 'collection',
  delivery: 'delivery',
};

// real order statuses
export const orderStatuses = {
  pending: 'pending',
  processing: 'processing',
  delivering: 'delivering',
  delivered: 'delivered',
  cancelled: 'cancelled',
};

export const orderStatusesNew = {
  pending: 'pending',
  processing: 'accepted',
  delivering: 'on the way',
  delivered: 'delivered',
  collected: 'collected',
  cancelled: 'cancelled',
};

export const orderStatusesNewRev = {
  pending: 'pending',
  accepted: 'processing',
  'on the way': 'delivering',
  delivered: 'delivered',
  collected: 'collected',
  cancelled: 'cancelled',
};

/**
 * Get mapped status for old to new statuses also-
 * returns delivered as collected for collection order
 * @var statusName : string
 * @var isCollectionOrder : boolean
  */
export const getOrderStatusNewLabel = (statusName, isCollectionOrder = false) => {
  const currentStatus = orderStatusesNew[statusName];

  return (isCollectionOrder && currentStatus === orderStatusesNew.delivered)
    ? orderStatusesNew.collected
    : currentStatus
    ;
}

export const renderEnabledBadge = (data, additionalClasses = 'fs15') => {
  const text = data ? 'enabled' : 'disabled';
  return renderStatus(text, additionalClasses);
};

/**
 * Logout from mobile app
 */
export const appLogout = () => {
  if (window.JSInterface) {
    window.JSInterface.handleAppLogout();

    return true;
  }

  return false;
};

/**
 * Reload in mobile app
 */
export const appReload = () => {
  if (window.JSInterface) {
    window.JSInterface.handleAppReload();

    return true;
  }

  return false;
};

export const axiosDefaultFormDataHeader = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
};

export const setDateTime = (date, hours, minute, second) => {

  const day = date;

  day.setHours(hours, minute, second);

  return day;
};

export const formatDateRanger = (date) => {

  if (!date) return;

  let start = null;
  let end = null;

  if (Array.isArray(date)) {
    start = date[0];
    end = date[1];

    const startDay = start.getDate();
    const startMonth = start.getMonth() + 1;
    const startYear = start.getFullYear();

    const endDay = end.getDate();
    const endMonth = end.getMonth() + 1;
    const endYear = end.getFullYear();

    return `${startDay}/${startMonth}/${startYear} - ${endDay}/${endMonth}/${endYear}`;
  }

};

export const getRemainingTime = (dateString) => {
// Set the date we're counting down to
  const requestedDate = new Date(Date.parse(dateString)).getTime();
    // Get today's date and time
    const now = new Date().getTime();

    // Find the distance between now and the count down date
    const timeDifference = requestedDate - now;

    // Time calculations for days, hours, minutes and seconds
    const hours = Math.floor((timeDifference) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  const reminingTime = {
    hours,
    minutes,
    seconds
  };

  return reminingTime;
};
