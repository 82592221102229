import defaultState from '../../default/state';
import mutations from './mutations';
import actions from './actions';

export default {

  state: {
    ...defaultState,
  },

  mutations,

  actions
};
