import defaultActions from '../default/actions';
import Seo from '@/Services/Seo';

export default {
  ...defaultActions,

  getSeoMeta: async ({ dispatch }) => {
    dispatch('setLoading', true);

    try {

      let response = await Seo.all();
      await dispatch('setData', response.data.data);
      await dispatch('setDataFetched', true);

    } catch (e) {

      dispatch('setErrors', e.errors);
      dispatch('setErrorMsg', e.message);

    }

    dispatch('setLoading', false);
  },

  deleteSeoMeta: async ({ getters, commit, dispatch }, id) => {
    try {

      let response = await Seo.delete(id);

      const allMetas = [...getters.seoMetas];
      const updatedMetas = allMetas.filter((item) => item.id !== id);

      commit('setSeoMeta', updatedMetas);

      return response;
    } catch (e) {
      dispatch('handleErrorResponse', e);
    }
  },

  createSeoMeta: async ({ getters, commit, dispatch }, data) => {

    try {

      const response = await Seo.create(data);

      const allItems = [...getters.seoMetas];
      allItems.unshift(response.data.data);

      commit('setSeoMeta', allItems);

      return response;
    } catch (e) {

      dispatch('handleErrorResponse', e);
      return Promise.reject(e);

    }
  },

  updateSeoMeta: async ({ getters, commit, dispatch }, { data, id }) => {

    try {

      const response = await Seo.update(id, data);

      const allItems = [...getters.seoMetas];
      const updatedData = allItems.map((item) => item.id !== id ? item : response.data.data);

      commit('setSeoMeta', updatedData);

      return response;
    } catch (e) {

      dispatch('handleErrorResponse', e);
      return Promise.reject(e);

    }
  },

  handleErrorResponse: ({ dispatch }, error) => {
    dispatch('setErrors', error.errors);
    dispatch('setErrorMsg', error.message);
  }
};
