import Store from '@/store/index';
import routeMap from '@/utils/routeMap';

export default async function (to, from, next) {

  const validated = await validateRequest();

  if (validated) {
    return next();
  }

  return next({ name: routeMap.dashboard.name });

}

const validateRequest = async () => {

  let isAuthenticated = Store.state.user['authenticated'];
  const bootstrapped = Store.state.app['bootstrapped'];

  if (bootstrapped) {
    return !isAuthenticated;
  }

  await Store.dispatch('app/setBootstrap');

  isAuthenticated = Store.state.user['authenticated'];

  return !isAuthenticated;

};



