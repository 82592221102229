<template>
  <label class="switch filer-control-btn">
    <input
      v-model="isChecked"
      :checked="isChecked"
      :class="classes"
      :disabled="disabled"
      :name="name"
      type="checkbox"
      @change="fireEvent(isChecked)"
    />
    <span>
      <slot>
        <template v-if="isChecked">{{ enableText }}</template>
        <template v-else>{{ disableText }}</template>
      </slot>
    </span>
  </label>
</template>

<script>
import { ref, watch, defineComponent } from 'vue';

export default defineComponent({
  name: 'switch-comp',
  emits: ['change', 'update:modelValue'],
  props: {

    modelValue: {
      type: Boolean,
      required: false
    },

    disabled: Boolean,
    classes: String,
    checked: Boolean,
    name: String,

    enableText: {
      type: String,
      default: ''
    },

    disableText: {
      type: String,
      default: ''
    }
  },

  setup (props, { emit }) {

    const isChecked = ref(props.checked);

    const fireEvent = (data) => {
      emit('update:modelValue', data);
      emit('change', data);
    };

    watch(isChecked, (nv) => {
      fireEvent(nv)
    })

    watch(() => props.checked, (nv) => {
      isChecked.value = nv;
       // fireEvent(nv);
    }, { deep: true, immediate: true });

    return {
      isChecked,
      fireEvent
    };
  }
});
</script>

<style lang="scss" scoped>
label.switch {
  input[type="checkbox"] {
    display: none;

    &:checked {
      + span {
        &:before {
          background-color: rgba(#007FEB, 0.5);
        }

        &:after {
          background-color: #007FEB;
          transform: translate(80%, -50%);
        }
      }
    }

    + span {
      position: relative;
      display: inline-block;
      cursor: pointer;
      font-weight: 500;
      text-align: left;
      margin: 0px;
      padding: 0px 44px;

      &:before,
      &:after {
        content: '';
        cursor: pointer;
        position: absolute;
        margin: 0;
        outline: 0;
        top: 50%;
        transform: translate(0, -50%);
        transition: all 200ms ease-out;
      }

      &:before {
        left: 1px;
        width: 34px;
        height: 14px;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 8px;
      }

      &:after {
        left: 0;
        width: 20px;
        height: 20px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .14), 0 2px 2px 0 rgba(0, 0, 0, .098), 0 1px 5px 0 rgba(0, 0, 0, .084);
      }
    }

    &:checked + span &:after {
      transform: translate(80%, -50%);
    }
  }
}
</style>
