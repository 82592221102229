import Api from '@/Services/Api';
import http from '@/Plugins/http';
import { GET_DELIVERY_CHARGES, CREATE_DELIVERY_CHARGES, UPDATE_DELIVERY_CHARGES, DELETE_DELIVERY_CHARGES } from '@/utils/Urls';

export default class DeliveryCharge {

  static all = () => Api.getResponse(() => http.get(GET_DELIVERY_CHARGES));

  static create = (data) => Api.getResponse(() => http.post(CREATE_DELIVERY_CHARGES, data));

  static update = (id, data) => Api.getResponse(() => http.patch(`${UPDATE_DELIVERY_CHARGES}/${id}`, data));

  static destroy = (id) => Api.getResponse(() => http.delete(`${DELETE_DELIVERY_CHARGES}/${id}`));
}
