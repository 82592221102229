import MainLayout from '@/layout/Main';
import authenticated from '@/Middleware/authenticated';
import guest from '@/Middleware/guest';
import routeMap from '@/utils/routeMap';
import middleware from '@/Middleware/middleware';
import authorized from '@/Middleware/authorized';

const authRoutes = [
  {
    path: routeMap.auth.path,
    component: () => import(/* webpackChunkName: "Simple-layout" */'@/layout/Simple'),
    children: [
      {
        path: routeMap.auth.login.path,
        name: routeMap.auth.login.name,
        component: () => import(/* webpackChunkName: "Login" */ '@/views/Auth/Login'),
        beforeEnter: guest,
        meta: {
          permission: routeMap.auth.login.permission
        }
      },
      {
        path: 'forgot',
        name: 'Forgot',
        component: () => import('@/views/Auth/Forgot'),
        // beforeEnter: guest,
        meta: {
          permission: routeMap.auth.login.permission
        }
      }
    ]
  },
  {
    path: routeMap.error.notFound.path,
    name: routeMap.error.notFound.name,
    component: () => import('@/views/Error/NotFoundError'),
    meta: {
      permission: routeMap.error.notFound.permission
    }
  }
];

const adminRoutes = [
  {
    path: '/',
    component: MainLayout,

    children: [
      {
        path: routeMap.dashboard.path,
        name: routeMap.dashboard.name,
        component: () => import('@/views/Dashboard/Dashboard'),
        beforeEnter: middleware([authenticated, authorized]),
        meta: {
          permission: routeMap.dashboard.permission
        }
      },

      {
        path: routeMap.order.path,
        name: routeMap.order.name,
        beforeEnter: middleware([authenticated, authorized]),
        component: () => import('@/views/Order/Order'),
        meta: {
          permission: routeMap.order.permission
        }
      },
      {
        path: routeMap.orderMenu.path,
        name: routeMap.orderMenu.name,
        beforeEnter: middleware([authenticated, authorized]),
        component: () => import('@/views/OrderMenu/OrderMenu'),
        meta: {
          permission: routeMap.orderMenu.permission
        }
      },

      {
        path: routeMap.reservation.path,
        name: routeMap.reservation.name,
        beforeEnter: middleware([authenticated, authorized]),
        component: () => import('@/views/Reservation/Reservation'),
        meta: {
          permission: routeMap.reservation.permission
        }
      },

      // menu
      {
        path: routeMap.menuCategories.path,
        name: routeMap.menuCategories.name,
        beforeEnter: middleware([authenticated, authorized]),
        component: () => import('@/views/Menu/Categories'),
        meta: {
          permission: routeMap.menuCategories.permission
        }
      },
      {
        path: routeMap.menuItems.path,
        name: routeMap.menuItems.name,
        beforeEnter: middleware([authenticated, authorized]),
        component: () => import('@/views/Menu/Items'),
        meta: {
          permission: routeMap.menuItems.permission
        }
      },
      {
        path: routeMap.menuAddons.path,
        name: routeMap.menuAddons.name,
        beforeEnter: middleware([authenticated, authorized]),
        component: () => import('@/views/Menu/Addons'),
        meta: {
          permission: routeMap.menuAddons.permission
        }
      },
      {
        path: routeMap.menuSetMenu.path,
        name: routeMap.menuSetMenu.name,
        beforeEnter: middleware([authenticated, authorized]),
        component: () => import('@/views/Menu/SetMenu'),
        meta: {
          permission: routeMap.menuSetMenu.permission
        }
      },

      // ACL
      {
        path: routeMap.aclUsers.path,
        name: routeMap.aclUsers.name,
        beforeEnter: middleware([authenticated, authorized]),
        component: () => import('@/views/AccessControl/Users'),
        meta: {
          permission: routeMap.aclUsers.permission
        }
      },
      {
        path: routeMap.aclGroups.path,
        name: routeMap.aclGroups.name,
        beforeEnter: middleware([authenticated, authorized]),
        component: () => import('@/views/AccessControl/Groups'),
        meta: {
          permission: routeMap.aclGroups.permission
        }
      },
      {
        path: routeMap.aclPermissions.path,
        name: routeMap.aclPermissions.name,
        beforeEnter: middleware([authenticated, authorized]),
        component: () => import('@/views/AccessControl/Permissions'),
        meta: {
          permission: routeMap.aclPermissions.permission
        }
      },

      // opening hour
      {
        path: routeMap.opening.path,
        name: routeMap.opening.name,
        beforeEnter: middleware([authenticated, authorized]),
        component: () => import('@/views/Opening/Opening'),
        meta: {
          permission: routeMap.opening.permission
        }
      },

      // Offer
      {
        path: routeMap.offer.path,
        name: routeMap.offer.name,
        beforeEnter: middleware([authenticated, authorized]),
        component: () => import('@/views/Offer/Offer'),
        meta: {
          permission: routeMap.offer.permission
        }
      },

      // Inactivity Ad
      {
        path: routeMap.inactivityAd.path,
        name: routeMap.inactivityAd.name,
        beforeEnter: middleware([authenticated, authorized]),
        component: () => import('@/views/InactivityAd/InactivityAdPage'),
        meta: {
          permission: routeMap.inactivityAd.permission
        }
      },

      // Customers
      {
        path: routeMap.customers.path,
        name: routeMap.customers.name,
        beforeEnter: middleware([authenticated, authorized]),
        component: () => import('@/views/Customer/Customer'),
        meta: {
          permission: routeMap.customers.permission
        }
      },

      // Settings
      {
        path: routeMap.settings.path,
        name: routeMap.settings.name,
        beforeEnter: middleware([authenticated, authorized]),
        component: () => import('@/views/Setting/AppSetting'),
        meta: {
          permission: routeMap.settings.permission
        }
      },

      // Delivery Charges
      {
        path: routeMap.deliveryCharge.path,
        name: routeMap.deliveryCharge.name,
        beforeEnter: middleware([authenticated, authorized]),
        component: () => import('@/views/DeliveryCharge/DeliveryCharge'),
        meta: {
          permission: routeMap.deliveryCharge.permission
        }
      },

      // SEO Meta
      {
        path: routeMap.seo.path,
        name: routeMap.seo.name,
        beforeEnter: middleware([authenticated, authorized]),
        component: () => import('@/views/SeoMeta/SeoMeta'),
        meta: {
          permission: routeMap.seo.permission
        }
      },

    ],
  },
];


const miscRoutes = [
  {
    path: routeMap.error.unauthorized.path,
    name: routeMap.error.unauthorized.name,
    component: () => import(/* webpackChunkName: "Unauthorized" */ '@/views/Error/Unauthorized'),
    meta: {
      permission: routeMap.error.unauthorized.permission
    }
  },
]

const routes = [...miscRoutes, ...authRoutes, ...adminRoutes];
export default routes;
