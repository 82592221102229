import defaultActions from '../default/actions';
import InactivityAdService from '@/Services/InactivityAd';
import { SET_DATA } from '@/store/modules/default/types';

export default {
  ...defaultActions,
  setData: ({ commit, state }, payload) => commit(SET_DATA, [...payload]),

  /**
   * @return {Promise<void>}
   */
  getAds: async ({ dispatch }) => {
    dispatch('setLoading', true);

    try {

      let response = await InactivityAdService.fetchAll();

      await dispatch('setData', response.data.data);
      await dispatch('setDataFetched', true);

    } catch (e) {

      dispatch('setErrors', e.errors);
      dispatch('setErrorMsg', e.message);

    }

    dispatch('setLoading', false);
  },

  createAd: async ({ dispatch, getters }, data) => {

    try {

      const formData = new FormData();

      Object.keys(data).map(d => {
        formData.append(d, data[d]);
      });

      const response = await InactivityAdService.create(formData);

      // Get Current items
      let ads = [
        response.data.data,
        ...getters.ads
      ];

      // override items to store
      await dispatch('setData', ads);

      return response;

    } catch (e) {

      await dispatch('setErrors', e.errors);
      await dispatch('setErrorMsg', e.message);
      return Promise.reject(e);

    }
  },

  updateAd: async ({ dispatch, getters }, { data, id }) => {

    try {
      const formData = new FormData();

      Object.keys(data).map(d => {
        formData.append(d, data[d]);
      });

      const response = await InactivityAdService.update(id, formData);

      const updatedAd = response.data.data;

      // Get Current items
      let ads = [...getters.ads];

      // push updated item to store
      const updatedAds = ads.map((item) => item.id !== id ? item : updatedAd);

      // override items to store
      await dispatch('setData', updatedAds);
      return response;

    } catch (e) {

      await dispatch('setErrors', e.errors);
      await dispatch('setErrorMsg', e.message);
      return Promise.reject(e);

    }
  },

  deleteAd: async ({ getters, dispatch }, id) => {

    try {

      let response = await InactivityAdService.destroy(id);
      const collection = getters.ads.filter((item) => item.id !== id);

      await dispatch('setData', collection);
      return response;

    } catch (e) {

      await dispatch('setErrors', e.errors);
      await dispatch('setErrorMsg', e.message);
      return Promise.reject(e);

    }
  }
};
