import defaultState from '../default/state';
import defaultMutations from '../default/mutations';
import actions from './actions';

export default {
  namespaced: true,

  state: () => ({
    ...defaultState,
  }),

  getters: {

    openings: (state) => state?.data?.openings || [],
    permissions: (state) => state.data.permissions

  },

  mutations: {
    ...defaultMutations,
    setOpening: (state, payload) => state.data.openings = payload
  },

  actions: {
    ...actions,
  }

};
