import defaultState from '../default/state';
import defaultMutations from '../default/mutations';
import actions from './actions';

export default {
  namespaced: true,

  state: () => ({
    ...defaultState,
  }),

  getters: {

    reservation: (state) => state.data.reservation || [],
    permissions: (state) => state.data.permissions,
    statuses: (state) => state.data.statuses,

  },

  mutations: {
    ...defaultMutations,
    setReservation: (state, payload) => state.data.reservation = payload
  },

  actions: {
    ...actions,
  }

};
