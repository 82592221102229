import Menu from '@/Services/Menu';
import {
  DATA_MODIFY_ACTION_ADD, DATA_MODIFY_ACTION_DELETE, DATA_MODIFY_ACTION_UPDATE,
  SET_DATA, SET_DATA_FETCHED,
  SET_ERROR_MSG,
  SET_ERRORS,
  SET_LOADING
} from '@/store/modules/default/types';
import { touchCollection } from '@/utils/Helper';

const statePath = 'setMenu';

export default {

  setSetMenuLoading: ({ commit }, payload) => commit(SET_LOADING, { path: statePath, data: payload }),
  setSetMenuDataFetched: ({ commit }, payload) => commit(SET_DATA_FETCHED, { path: statePath, data: payload }),
  setSetMenuData: ({ commit }, payload) => commit(SET_DATA, { path: statePath, data: payload }),
  setSetMenuErrors: ({ commit }, payload) => commit(SET_ERRORS, { path: statePath, data: payload }),
  setSetMenuErrorMsg: ({ commit }, payload) => commit(SET_ERROR_MSG, { path: statePath, data: payload }),


  fetchSetMenus: async ({ dispatch }) => {

    dispatch('setSetMenuLoading', true);

    try {

      const response = await Menu.getSetMenus();
      const { data } = response.data;

      await dispatch('setSetMenuData', data);
      await dispatch('setSetMenuDataFetched', true);

    } catch (e) {

      await dispatch('setSetMenuData', {});
      await dispatch('setSetMenuErrors', e?.errors);
      await dispatch('setSetMenuErrorMsg', e.message);

    }

    dispatch('setSetMenuLoading', false);

  },

  createSetMenu: async ({ dispatch, state }, payload) => {

    const response = await Menu.createSetMenu(payload);
    const { data } = response.data;

    const newSetMenus = [
      ...touchCollection(state.setMenu.data, data, DATA_MODIFY_ACTION_ADD)
    ];

    await dispatch('setSetMenuData', newSetMenus);

    return data;
  },

  updateSetMenu: async ({ dispatch, state }, { id, data }) => {

    const response = await Menu.updateSetMenu(id, data);
    const { data: result } = response.data;

    const newSetMenus = [
      ...touchCollection(state.setMenu.data, result, DATA_MODIFY_ACTION_UPDATE)
    ];

    await dispatch('setSetMenuData', newSetMenus);

    return data;
  },

  deleteSetMenu: async ({ dispatch, state }, payload) => {

    const response = await Menu.destroySetMenu(payload);
    const { data } = response.data;

    const newSetMenu = [
      ...touchCollection(state.setMenu.data, payload, DATA_MODIFY_ACTION_DELETE)
    ];

    await dispatch('setSetMenuData', newSetMenu);

    return data;
  },

};
