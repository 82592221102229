import { SET_DATA, SET_DATA_FETCHED, SET_ERROR_MSG, SET_ERRORS, SET_LOADING } from '@/store/modules/default/types';

export default {
  setLoading: ({ commit }, payload) => commit(SET_LOADING, payload),
  setDataFetched: ({ commit }, payload) => commit(SET_DATA_FETCHED, payload),
  setData: ({ commit, state }, payload) => commit(SET_DATA, {
    ...state.data,
    ...payload
  }),
  setErrors: ({ commit }, payload) => commit(SET_ERRORS, payload),
  setErrorMsg: ({ commit }, payload) => commit(SET_ERROR_MSG, payload),
};
