import routeMap from '@/utils/routeMap';
import { isAuthorized } from '@/utils/Helper';

const app = {

  data: () => ({
    routeMap: routeMap,
  }),

  methods: {

    renderStatus (item) {

      let badgeClass = '';

      switch (item) {
        case 'pending':
          badgeClass = 'badge-dark';
          break;

        case 'processing':
          badgeClass = 'badge-light';
          break;

        case 'delivering':
          badgeClass = 'badge-primary';
          break;

        case 'delivered':
          badgeClass = 'badge-success';
          break;

        case 'cancelled':
          badgeClass = 'badge-secondary';
          break;

        default:
          badgeClass = 'badge-danger';
      }

      return `
                <span class="badge table-badge ${badgeClass} badge-pill text-capitalize order-action">
                    ${item}
                </span>
            `;
    },

    isAuthorized (permissions) {
      return isAuthorized(permissions);
    },
  }
};

export default app;
