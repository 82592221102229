import AccessControl from '@/Services/AccessControl';
import {
  DATA_MODIFY_ACTION_ADD,
  DATA_MODIFY_ACTION_DELETE,
  DATA_MODIFY_ACTION_UPDATE,
  SET_DATA, SET_DATA_FETCHED,
  SET_ERROR_MSG,
  SET_ERRORS,
  SET_LOADING
} from '@/store/modules/default/types';
import { touchCollection } from '@/utils/Helper';

const statePath = 'groups';

export default {

  setGroupsLoading: ({ commit }, payload) => commit(SET_LOADING, { path: statePath, data: payload }),
  setGroupsDataFetched: ({ commit }, payload) => commit(SET_DATA_FETCHED, { path: statePath, data: payload }),
  setGroupsData: ({ commit }, payload) => commit(SET_DATA, { path: statePath, data: payload }),
  setGroupsErrors: ({ commit }, payload) => commit(SET_ERRORS, { path: statePath, data: payload }),
  setGroupsErrorMsg: ({ commit }, payload) => commit(SET_ERROR_MSG, { path: statePath, data: payload }),

  fetchGroups: async ({ dispatch }) => {

    dispatch('setGroupsLoading', true);

    try {

      const response = await AccessControl.getGroups();
      const { data } = response.data;

      await dispatch('setGroupsData', data);
      await dispatch('setGroupsDataFetched', true);

    } catch (e) {

      await dispatch('setGroupsData', {});
      await dispatch('setGroupsErrors', e?.errors);
      await dispatch('setGroupsErrorMsg', e.message);

    }

    dispatch('setGroupsLoading', false);

  },

  createGroup: async ({ dispatch, state }, payload) => {

    const response = await AccessControl.createGroup(payload);
    const { data } = response.data;

    const newGroups = {
      ...state.groups.data,
      groups: touchCollection(state.groups.data.groups, data, DATA_MODIFY_ACTION_ADD)
    };

    await dispatch('setGroupsData', newGroups);

    return data;
  },

  updateGroup: async ({ dispatch, state }, { id, data }) => {

    const response = await AccessControl.updateGroup(id, data);
    const { data: result } = response.data;

    const newGroups = {
      ...state.groups.data,
      groups: touchCollection(state.groups.data.groups, result, DATA_MODIFY_ACTION_UPDATE)
    };

    await dispatch('setGroupsData', newGroups);

    return data;
  },

  deleteGroup: async ({ dispatch, state }, payload) => {

    const response = await AccessControl.destroyGroup(payload);
    const { data } = response.data;

    const newGroups = {
      ...state.groups.data,
      groups: touchCollection(state.groups.data.groups, payload, DATA_MODIFY_ACTION_DELETE)
    };

    await dispatch('setGroupsData', newGroups);

    return data;
  },

};
