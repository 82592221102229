import Api from '@/Services/Api';
import http from '@/Plugins/http';
import {
  APP_SETTINGS_GET,
  APP_SETTING_CREATE,
  APP_SETTING_UPDATE,
  APP_SETTING_DELETE,
  APP_SETTING_CLEAR_CACHE,
  APP_SETTING_DEBUG, APP_SETTING_UPDATE_TIMING, APP_SETTING_UPDATE_PROMOTIONAL_BANNER
} from '@/utils/Urls';
import { axiosDefaultFormDataHeader } from '@/utils/Helper';

export default class Settings {

  // app Setting
  static getSettings = () => Api.getResponse(() => http.get(APP_SETTINGS_GET));

  /**
   * @param data : FormData
   * @return Promise
   * @throws Error
   */
  static createSetting = (data) => Api.getResponse(() => {

    if (!(data instanceof FormData)) {
      throw new Error("'Data' must be of type FormData");
    }

    return http.post(APP_SETTING_CREATE, data, axiosDefaultFormDataHeader);
  });

  /**
   * @param id : Number
   * @param data : FormData
   * @return Promise
   * @throws Error
   */
  static updateSetting = (id, data) => Api.getResponse(() => {

    if (!(data instanceof FormData)) {
      throw new Error("'Data' must be of type FormData");
    }

    //add patch method, this is required for laravel/php
    data.append('_method', 'patch');

    return http.post(`${APP_SETTING_UPDATE}${id}`, data, axiosDefaultFormDataHeader);
  });

  static updatePromotionalBannerSettings = (data) => Api.getResponse(() => {

    if (!(data instanceof FormData)) {
      throw new Error("'Data' must be of type FormData");
    }

    //add patch method, this is required for laravel/php
    data.append('_method', 'patch');

    return http.post(`${APP_SETTING_UPDATE_PROMOTIONAL_BANNER}`, data, axiosDefaultFormDataHeader);
  });

  static destroySetting = (id) => Api.getResponse(() => http.delete(`${APP_SETTING_DELETE}${id}`));


  static updateDelayTiming = (data) => Api.getResponse(() => http.post(APP_SETTING_UPDATE_TIMING, data));

  static debugSettings = () => Api.getResponse(() => http.get(APP_SETTING_DEBUG));

  // other settings
  static clearStoredCache = () => Api.getResponse(() => http.get(APP_SETTING_CLEAR_CACHE));
}
