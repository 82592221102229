import AccessControl from '@/Services/AccessControl';
import {
  DATA_MODIFY_ACTION_UPDATE,
  SET_DATA, SET_DATA_FETCHED,
  SET_ERROR_MSG,
  SET_ERRORS,
  SET_LOADING
} from '@/store/modules/default/types';
import { touchCollection } from '@/utils/Helper';

const statePath = 'users';

export default {

  setUsersDataFetched: ({ commit }, payload) => commit(SET_DATA_FETCHED, { path: statePath, data: payload }),
  setUsersData: ({ commit }, payload) => commit(SET_DATA, { path: statePath, data: payload }),
  setUsersLoading: ({ commit }, payload) => commit(SET_LOADING, { path: statePath, data: payload }),
  setUsersErrors: ({ commit }, payload) => commit(SET_ERRORS, { path: statePath, data: payload }),
  setUsersErrorMsg: ({ commit }, payload) => commit(SET_ERROR_MSG, { path: statePath, data: payload }),


  fetchUsers: async ({ dispatch }) => {

    dispatch('setUsersLoading', true);

    try {

      const response = await AccessControl.getUsers();
      const { data } = response.data;

      await dispatch('setUsersData', data);
      await dispatch('setUsersDataFetched', true);

    } catch (e) {

      await dispatch('setUsersData', {});
      await dispatch('setUsersErrors', e?.errors);
      await dispatch('setUsersErrorMsg', e.message);

    }

    dispatch('setUsersLoading', false);

  },

  updateUser: async ({ dispatch, state }, { id, data }) => {

    const response = await AccessControl.updateUser(id, data);
    const { data: result } = response.data;

    const newUsers = {
      ...state.users.data,
      users: touchCollection(state.users.data.users, result, DATA_MODIFY_ACTION_UPDATE)
    };

    await dispatch('setUsersData', newUsers);

    return data;
  },

};
