import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,

  state: () => ({
    loading: false,
    bootstrapped: false,
    layout: {
      sidebar: {
        hovered: false,
        expanded: true
      }
    },
    newOrder: {
      sound: {
        enabled: true,
        play: false
      },
      count: 0
    },
    newReservation: {
      count: 0
    }
  }),

  mutations,

  actions,

  getters: {
    ...getters
  }
}
