import defaultState from '../default/state';
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,

  state: () => ({
    ...defaultState,
    authenticated: false,
    tokenValidated: false,
    permissions: [],
  }),

  mutations,

  actions
}
