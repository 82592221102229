import defaultActions from '../default/actions';
import Dashboard from '@/Services/Dashboard';

export default {
  ...defaultActions,

  getDashboardContent: async ({ dispatch }) => {
    dispatch('setLoading', true);

    try {
      let response = await Dashboard.getContent();
      dispatch('setData', response.data.data);
      dispatch('setDataFetched', true);
    } catch (e) {
      await dispatch('setErrors', e.errors);
      await dispatch('setErrorMsg', e.message);
    }

    dispatch('setLoading', false);
  },

  updateOrder: async ({ dispatch, getters }, order) => {

    const currentOrders = [...getters.orders];

    const updatedOrders = currentOrders.map(item => item.id !== order.id ? item : order);

    dispatch('setData', {
      orders: updatedOrders
    });

  },

  updateOrAddOrder: ({ dispatch, getters }, updatedOrder) => {

    const currentOrders = [...getters.orders];

    const existingOrderPos = currentOrders.findIndex(order => order.id === updatedOrder.id);

    let updatedList = currentOrders;

    // when updated order doesn't exists
    if (existingOrderPos === -1) {

      // add updated order
      updatedList = [
        updatedOrder,
        ...currentOrders
      ];
    } else {

      // replace with updated order
      updatedList.splice(existingOrderPos, 1, updatedOrder);
    }

    dispatch('setData', {
      orders: updatedList
    });
  },

  incrementOrderCount ({ dispatch, state }) {
    dispatch('setData', {
      orderCount: (state.data.orderCount + 1)
    });
  },

  decrementOrderCount ({ dispatch, state }) {
    dispatch('setData', {
      orderCount: (state.data.orderCount - 1)
    });
  },

  updateOrAddReservation: ({ dispatch, getters }, updatedReservation) => {

    const currentReservations = [...getters.reservations];

    const existingReservationPos = currentReservations.findIndex(reservation => reservation.id === updatedReservation.id);

    let updatedList = currentReservations;

    // when updated order doesn't exists
    if (existingReservationPos === -1) {

      // add updated order
      updatedList = [
        updatedReservation,
        ...currentReservations
      ];
    } else {

      // replace with updated order
      updatedList.splice(existingReservationPos, 1, updatedReservation);
    }

    dispatch('setData', {
      reservations: updatedList
    });
  },

};
