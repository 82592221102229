export const SET_BOOTSTRAPPED = 'set_bootstrapped';

// layout
export const SET_HOVERED = 'set_hovered';
export const SET_EXPANDED = 'set_expanded';

// new order
export const SET_SOUND = 'set_sound';
export const SET_SOUND_PLAY = 'set_sound_play';
export const SET_NEW_ORDER_COUNT = 'set_new_order_count';

export const SET_NEW_RESERVATION_COUNT = 'set_new_reservation_count';
