const permissions = {

  admin_login: 'admin_login',

  dashboard_view: 'dashboard_view',

  order_view: 'order_view',
  order_update: 'order_update',
  order_delete: 'order_delete',
  order_print: 'order_print',

  reservation_view: 'reservation_view',
  reservation_update: 'reservation_update',
  reservation_delete: 'reservation_delete',

  menu_category_view: 'menu_category_view',
  menu_category_create: 'menu_category_create',
  menu_category_update: 'menu_category_update',
  menu_category_delete: 'menu_category_delete',

  menu_item_view: 'menu_item_view',
  menu_item_create: 'menu_item_create',
  menu_item_update: 'menu_item_update',
  menu_item_delete: 'menu_item_delete',

  //menu_set_menu_item_view: 'menu_item_view',
  //menu_set_menu_item_create: 'menu_item_create',
  //menu_set_menu_item_update: 'menu_item_update',
  //menu_set_menu_item_delete: 'menu_item_delete',

  menu_set_menu_view: 'menu_set_menu_view',
  menu_set_menu_create: 'menu_set_menu_create',
  menu_set_menu_update: 'menu_set_menu_update',
  menu_set_menu_delete: 'menu_set_menu_delete',

  menu_addon_view: 'menu_addon_view',
  menu_addon_create: 'menu_addon_create',
  menu_addon_update: 'menu_addon_update',
  menu_addon_delete: 'menu_addon_delete',

  offer_view: 'offer_view',
  offer_create: 'offer_create',
  offer_update: 'offer_update',
  offer_delete: 'offer_delete',

  opening_hour_view: 'opening_hour_view',
  opening_hour_create: 'opening_hour_create',
  opening_hour_update: 'opening_hour_update',
  opening_hour_delete: 'opening_hour_delete',

  delivery_charge_view: 'delivery_charge_view',
  delivery_charge_create: 'delivery_charge_create',
  delivery_charge_update: 'delivery_charge_update',
  delivery_charge_delete: 'delivery_charge_delete',

  acl_user_view: 'acl_user_view',
  acl_user_update: 'acl_user_update',

  acl_group_view: 'acl_group_view',
  acl_group_create: 'acl_group_create',
  acl_group_update: 'acl_group_update',
  acl_group_delete: 'acl_group_delete',

  acl_permission_view: 'acl_permission_view',
  app_setting_view: 'app_setting_view',
  app_setting_create: 'app_setting_create',
  app_setting_update: 'app_setting_update',
  app_setting_delete: 'app_setting_delete',

  seo_view: 'seo_view',
  seo_create: 'seo_create',
  seo_update: 'seo_update',
  seo_delete: 'seo_delete',

  customers_view: 'customers_view',
};

const permissionsGroup = {
  menu: [permissions.menu_category_view, permissions.menu_item_view, permissions.menu_item_view],

  acl: [permissions.acl_user_view, permissions.acl_group_view, permissions.acl_permission_view],

  order: [permissions.order_update, permissions.order_delete, permissions.order_print],

  reservation: [permissions.reservation_update, permissions.reservation_delete],

  categories: [permissions.menu_category_update, permissions.menu_category_delete],

  items: [permissions.menu_item_update, permissions.menu_item_delete],

  addons: [permissions.menu_addon_update, permissions.menu_addon_delete],

  deliveryCharges: [permissions.delivery_charge_update, permissions.delivery_charge_delete],

  openingHours: [permissions.opening_hour_update, permissions.opening_hour_delete],

  offer: [permissions.offer_update, permissions.offer_delete],

  SeoMeta: [permissions.seo_update, permissions.seo_delete],

  appSetting: [permissions.app_setting_create, permissions.app_setting_update, permissions.app_setting_delete],

  aclGroups: [permissions.acl_group_update, permissions.acl_group_delete],

  setMenu: [permissions.menu_set_menu_update, permissions.menu_set_menu_delete]
};

export { permissionsGroup };

export default permissions;
