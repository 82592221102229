<template>
  <li
    class="sidebar-dropdown"
    :class="{'active': state.isActive}"
  >
    <a
      @click="handleClick"
    >
      <i :class="icon"/>
      <span class="menu-text">{{ title }}</span>
    </a>
    <div class="sidebar-submenu">
      <ul>
        <MenuItem
          v-for="route in validRoutes"
          :key="route.path"
          :route-name="route.name"
          :title="route.title"
          :sub-route="true"
        />
      </ul>
    </div>
  </li>
</template>

<script>
import { isAuthorized, routeIs } from '@/utils/Helper';
import { computed, reactive, watch } from 'vue';
import MenuItem from '@/components/Util/LeftSidebar/MenuItem';
import { useRoute } from 'vue-router';

export default {
  name: 'MenuItemGroup',
  components: { MenuItem },
  props: {
    icon: {
      type: String,
      default: ''
    },
    routes: {
      type: Array,
    },
    title: {
      type: String,
    },
  },

  setup (props) {

    const route = useRoute();

    const routeNames = props.routes.map(r => r.name);

    const state = reactive({
      names: routeNames,
      isActive: !!routeIs(routeNames).length
    });

    const validRoutes = computed(() => {
      const currentRoutes = props.routes || [];

      const validRoutes = [];

      currentRoutes.map((routeItem) => {
        if (isAuthorized(routeItem.permission)) {
          validRoutes.push(routeItem);
        }
      });

      return validRoutes;
    });

    watch(route, (nv) => {
      state.isActive = routeNames.includes(nv.name);
    });

    const handleClick = () => {
      state.isActive = !state.isActive;
    };

    return {
      state,
      routeIs,
      handleClick,
      isAuthorized,
      validRoutes
    };
  }
};
</script>

<style scoped>

</style>
