<template>
  <ModalComp
    :show="state.showAd"
    header-classes="d-none"
    custom-class="w-100 h-100 inactivity-advert"
    max-width="100%"
    max-height="100%"
    :show-action-button="false"
  >

    <el-carousel
      ref="sliderRef"
      :autoplay="false"
      indicator-position="none"
      arrow="never"
      height="100vh"
      @change="handleSlideChange"
    >
      <el-carousel-item
        v-for="(slide, index) in state.slides"
        :key="slide"
      >
        <InactivityAdItem
          :slideData="slide"
          :current-slide-shown="index === state.currentSlideIndex"
        />

      </el-carousel-item>
    </el-carousel>

  </ModalComp>
</template>

<script>
import ModalComp from '@/components/Util/ModalComp';
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { ceil } from 'lodash';
import { AD_CHANGE_TIME, AD_CHECK_TIME, AD_MAX_IDLE_TIME } from '@/utils/constants';
import InactivityAdItem from '@/components/InactivityAd/InactivityAdItem';
import { useStore } from 'vuex';
import { collect } from 'collect.js';

export default {
  name: 'InactivityAd',
  components: { InactivityAdItem, ModalComp },

  setup () {

    const store = useStore();

    const state = reactive({
      showAd: false,

      inactiveSeconds: 0, // seconds
      checkInterval: null,

      currentSlideIndex: 0,
      slides: computed(() => {
        const ads = store.getters['inactivityAd/ads'];
        const enabledAds = ads.filter(ad => ad.status);

        return collect(enabledAds).sortByDesc(ad => ad.sort).toArray();
      }),
    });

    const sliderRef = ref(null);

    // after this duration ad will show
    const maxIdleTimeSeconds = AD_MAX_IDLE_TIME;

    // after this duration check for max duration reach
    const checkTimeSeconds = AD_CHECK_TIME;


    const closeAd = () => {
      state.showAd = false;
      resetInactivityTime();
    };

    function incrementInactivityTime () {

      // convert minute to ms
      const intervalMS = ceil(checkTimeSeconds * 1000);

      state.checkInterval = setInterval(() => {

        // increment if ad is not shown
        if (!state.showAd) {
          state.inactiveSeconds += checkTimeSeconds;
        }

      }, intervalMS);

    }

    function resetInactivityTime () {
      state.inactiveSeconds = 0;
    }

    function attachListeners () {
      document.addEventListener('scroll', resetInactivityTime, { capture: true, passive: true });
      document.addEventListener('click', closeAd, { capture: true, passive: true });
      document.addEventListener('touchstart', closeAd, { capture: true, passive: true });
    }

    function clearListeners () {
      // remove check interval
      if (state.checkInterval !== null) {
        clearInterval(state.checkInterval);
      }

      // remove listener
      document.removeEventListener('scroll', resetInactivityTime);
      document.removeEventListener('click', closeAd);
      document.removeEventListener('touchstart', closeAd);
    }

    function changeSlide (next = true) {
      const slider = sliderRef.value;

      if (!slider) return;

      if (next) {
        slider.next();
        return;
      }

      slider.prev();
    }

    function getSlideChangeTime (slideIndex) {

      const adChangeTime = (AD_CHANGE_TIME * 1000);

      // when no index provided use default
      if (slideIndex === undefined) return adChangeTime;

      const slideTime = state.slides[slideIndex]?.duration ?? 0;

      // if slide duration not set then use default or slide duration
      return (slideTime < 1) ? adChangeTime : (slideTime * 1000);
    }

    const handleSlideChange = (slideIndex) => {

      state.currentSlideIndex = slideIndex;

      const changeTime = getSlideChangeTime(slideIndex);

      // queue the next slide
      setTimeout(() => {
        changeSlide();
      }, changeTime);
    };

    // counts inactive seconds to open ad
    watch(() => state.inactiveSeconds, (nv) => {

      // when max inactivity duration reached
      if (nv >= maxIdleTimeSeconds) {

        // don't show ads if ad unavailable
        if (!state.slides.length) {
          resetInactivityTime();
          return;
        }

        // show ad
        state.showAd = true;

        // reset elapsed duration
        resetInactivityTime();
      }
    });

    // plays slide show when ad fist shows up
    watch(() => state.showAd, (nv) => {
      if (!nv) return;

      const firstSlideTime = getSlideChangeTime(0);

      setTimeout(() => {
        changeSlide();
      }, firstSlideTime);
    });

    // close ad when new order/ reservation comes
    watch([
      () => store.getters['app/getNewOrdersCount'],
      () => store.getters['app/getNewReservationCount']
    ], () => closeAd());

    onMounted(async () => {
      incrementInactivityTime();
      attachListeners();
    });

    onUnmounted(() => {
      clearListeners();
    });

    return {
      state,
      sliderRef,
      closeAd,
      handleSlideChange
    };
  }
};
</script>

<style lang="scss">
.inactivity-advert {
  .p-dialog-content {
    height: 100% !important;
    padding: 0 !important;
    background-color: black !important;
  }

  .p-carousel-prev,
  .p-carousel-next,
  .p-carousel-indicators
  {
    display: none;
  }
}
</style>
