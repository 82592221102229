<template>
  <div
    class="page-wrapper"
    :class="{
      [sidebarStateClass] : !!sidebarStateClass,
      'sidebar-hovered': state.hovered
    }"
  >
    <WSHandler />

    <!-- Sidebar   -->
    <Sidebar />
    <!-- Sidebar   -->

    <div class="page-content">
      <TopNav />

      <div class="main-container">
        <div class="page-header">
          <TopBreadCrumb />
        </div>
        <div v-loading="loading">
          <router-view></router-view>
        </div>
      </div>

      <AppFooter />
    </div>

    <InactivityAd />
  </div>
</template>

<script>
import Sidebar from '@/layout/Partials/Sidebar';
import TopNav from '@/layout/Partials/TopNav';
import TopBreadCrumb from '@/components/Util/TopBreadCrumb';
import AppFooter from '@/layout/Partials/AppFooter';
import { useStore } from 'vuex';
import { computed, onMounted, onUnmounted, reactive, toRefs } from 'vue';
import WSHandler from '@/layout/WSHandler';
import InactivityAd from '@/components/InactivityAd/InactivityAd';

export default {
  components: { InactivityAd, WSHandler, AppFooter, TopBreadCrumb, TopNav, Sidebar },

  setup () {
    const store = useStore();
    const sidebarState = store.state.app.layout.sidebar;

    const state = reactive({
      loading: store.state.app.loading,
      windowSize: { x: 0, y: 0 },
      sidebarStateClass: computed(() => {

        let sidebarClass = sidebarState.expanded ? '' : 'pinned';
        const isLowerDevice = state.windowSize.x > 767;
        return isLowerDevice ? sidebarClass : `${sidebarClass} ${sidebarState.expanded ? 'toggled' : ''}`;

      })
    });

    // get user resize interaction
    const resizeListener = () => {
      ({ innerWidth: state.windowSize.x, innerHeight: state.windowSize.y } = window);
    };

    onMounted(() => {
      window.addEventListener('resize', resizeListener, { passive: true });
    });

    onUnmounted(() => window.removeEventListener('resize', resizeListener));

    return {
      ...toRefs(state),
      state: sidebarState
    };
  }
};
</script>
