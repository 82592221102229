import Menu from '@/Services/Menu';
import {
  DATA_MODIFY_ACTION_ADD, DATA_MODIFY_ACTION_DELETE, DATA_MODIFY_ACTION_UPDATE,
  SET_DATA, SET_DATA_FETCHED,
  SET_ERROR_MSG,
  SET_ERRORS,
  SET_LOADING
} from '@/store/modules/default/types';
import { touchCollection } from '@/utils/Helper';

const statePath = 'addons';

export default {

  setAddonDataFetched: ({ commit }, payload) => commit(SET_DATA_FETCHED, { path: statePath, data: payload }),
  setAddonData: ({ commit }, payload) => commit(SET_DATA, { path: statePath, data: payload }),
  setAddonLoading: ({ commit }, payload) => commit(SET_LOADING, { path: statePath, data: payload }),
  setAddonErrors: ({ commit }, payload) => commit(SET_ERRORS, { path: statePath, data: payload }),
  setAddonErrorMsg: ({ commit }, payload) => commit(SET_ERROR_MSG, { path: statePath, data: payload }),


  fetchAddons: async ({ dispatch }) => {

    dispatch('setAddonLoading', true);

    try {

      const response = await Menu.getAddons();
      const { data } = response.data;

      await dispatch('setAddonData', data);
      await dispatch('setAddonDataFetched', true);

    } catch (e) {

      await dispatch('setAddonData', {});
      await dispatch('setAddonErrors', e?.errors);
      await dispatch('setAddonErrorMsg', e.message);

    }

    dispatch('setAddonLoading', false);

  },

  createAddon: async ({ dispatch, state }, payload) => {

    const response = await Menu.createAddon(payload);
    const { data } = response.data;

    const newAddons = {
      ...state.addons.data,
      addons: touchCollection(state.addons.data.addons, data, DATA_MODIFY_ACTION_ADD)
    };

    await dispatch('setAddonData', newAddons);

    return data;
  },

  updateAddon: async ({ dispatch, state }, { id, data }) => {

    const response = await Menu.updateAddon(id, data);
    const { data: result } = response.data;

    const newAddons = {
      ...state.addons.data,
      addons: touchCollection(state.addons.data.addons, result, DATA_MODIFY_ACTION_UPDATE)
    };

    await dispatch('setAddonData', newAddons);

    return data;
  },

  deleteAddon: async ({ dispatch, state }, payload) => {

    const response = await Menu.destroyAddon(payload);
    const { data } = response.data;

    const newAddons = {
      ...state.addons.data,
      addons: touchCollection(state.addons.data.addons, payload, DATA_MODIFY_ACTION_DELETE)
    };

    await dispatch('setAddonData', newAddons);

    return data;
  },

};
