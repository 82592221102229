import defaultActions from '../default/actions';
import Order from '@/Services/Order';

export default {
  ...defaultActions,

  getOrders: async ({ dispatch }) => {

    dispatch('setLoading', true);

    let response = await Order.getOrder();

    dispatch('setLoading', false);
    dispatch('setDataFetched', true);

    dispatch('setData', response.data.data);
  },

  deleteOrder: async ({ commit, dispatch, getters }, id) => {

    try {

      const response = await Order.destroy(id);

      let currentState = [...getters.orders];
      let updatedState = currentState.filter((item) => item.id !== id);

      commit('setOrder', updatedState);
      return response;

    } catch (e) {

      await dispatch('setErrors', e.errors);
      await dispatch('setErrorMsg', e.message);

      return Promise.reject(e);
    }
  },

  updateOrder: async ({ commit, dispatch, getters }, { id, data }) => {
    try {

      let response = await Order.update(id, data);

      const updatedOrder = response.data.data;

      const currentOrderItem = [...getters.orders];
      const updatedItem = currentOrderItem.map(item => item.id !== id ? item : updatedOrder);

      commit('setOrder', updatedItem);

      // update dashboard orders
      dispatch('dashboard/updateOrAddOrder', updatedOrder, { root: true });

      return response;

    } catch (e) {

      await dispatch('setErrors', e.errors);
      await dispatch('setErrorMsg', e.message);

      return Promise.reject(e);

    }
  },

  updateOrAddOrder: ({ dispatch, getters }, updatedOrder) => {

    const currentOrders = [...getters.orders];

    const existingOrderPos = currentOrders.findIndex(order => order.id === updatedOrder.id);

    let updatedList = currentOrders;

    // when updated order doesn't exists
    if (existingOrderPos === -1) {

      // add updated order
      updatedList = [
        updatedOrder,
        ...currentOrders
      ];
    } else {

      // replace with updated order
      updatedList.splice(existingOrderPos, 1, updatedOrder);
    }

    dispatch('setData', {
      orders: updatedList
    });
  }

};
