<template>
  <div class="">

    <slot :play="startPlay"></slot>

    <audio
      ref="soundRef"
      :controls="show"
      @canplay="handleReady"
      @ended="handleEnd"
    >
      <source
        :src="filePath"
        type="audio/mpeg"
      />
      <span>
        Your browser does not support the audio element.
      </span>
    </audio>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from 'vue';

export default {
  name: 'soundComp',
  props: {
    filePath: {
      type: String,
      required: true
    },
    play: {
      type: Boolean,
      default: false
    },
    enabled: {
      type: Boolean,
      default: true
    },
    show: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: false
    },
    loopInterval: { // in ms
      type: Number,
      default: 1000
    },
    autoplay: {
      type: Boolean,
      default: false
    }
  },

  setup (props, { emit }) {

    const state = reactive({
      player: {},
    });

    const soundRef = ref(null);

    watch(() => props.enabled, (nv) => {
      // if disabling sound
      if (!nv) {
        stop();
      }
    });

    watch(() => props.play, (nv) => {
      // if disabling sound
      if (props.enabled && nv) {
        startPlay();
      }
    });

    onMounted(() => {
      state.player = soundRef;
    });

    function handleReady () {

      // autoPlay
      if (props.autoplay) {
        startPlay();
      }

      emit('ready', true);
    }

    function handleEnd () {

      if (props.loop) {
        setTimeout(() => {
          startPlay();
        }, props.loopInterval);
      }

      emit('ended', true);
    }

    function startPlay () {
      if (props.enabled) {
        state.player.play();
      }
    }

    function stop () {

      // if just ended playing then try to stop bit later
      if (!state.player.paused) {
        setTimeout(() => {

          // if paused stop it
          if (state.player.paused) {
            stopPlayback();
            return;
          }

          // if not paused then stop later
          setTimeout(() => stopPlayback(), 800);
        }, 600);
        return;
      }

      // stop playing
      stopPlayback();
    }

    function stopPlayback () {
      state.player.pause();
      state.player.currentTime = 0;
    }

    return {
      state,
      soundRef,
      handleReady,
      handleEnd,
      startPlay
    }
  }
};
</script>

<style scoped>

</style>
