import defaultActions from '../default/actions';
import Opening from '@/Services/Opening';
import { collect } from 'collect.js';

export default {
  ...defaultActions,

  getOpeningHours: async ({ dispatch }) => {
    dispatch('setLoading', true);

    try {

      let response = await Opening.getOpenings();
      await dispatch('setData', response.data.data);
      await dispatch('setDataFetched', true);

    } catch (e) {

      dispatch('setErrors', e.errors);
      dispatch('setErrorMsg', e.message);

    }

    dispatch('setLoading', false);
  },

  createOpening: async ({ dispatch, getters, commit }, data) => {

    try {

      let response = await Opening.create(data);
      let openingData = [...getters.openings];
      let updated = openingData.concat(response.data.data);
      commit('setOpening', updated);
      return response;

    } catch (e) {

      dispatch('setErrors', e.errors);
      dispatch('setErrorMsg', e.message);

      return Promise.reject(e);

    }
  },

  updateOpening: async ({ dispatch, getters, commit }, { data, id }) => {

    try {

      let response = await Opening.update(id, data);
      let openingData = [...getters.openings];

      const updatedOpeningData = openingData.map((item) => item.id !== id ? item : response.data.data);

      commit('setOpening', updatedOpeningData);

      return response;

    } catch (e) {

      dispatch('setErrors', e.errors);
      dispatch('setErrorMsg', e.message);

      return Promise.reject(e);

    }
  },

  deleteOpening: async ({ commit, dispatch, getters }, id) => {
    try {

      const response = await Opening.destroy(id);
      const allOpenings = getters.openings
      const updated = collect(allOpenings).filter((item) => item.id !== id).toArray();
      commit('setOpening', updated)
      return response;

    } catch (e) {

      dispatch('setErrors', e.errors);
      dispatch('setErrorMsg', e.message);

      return Promise.reject(e);
    }
  }

};
