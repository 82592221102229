export default {
  orders: (state) => state.data.orders,
  orderStatuses: (state) => state.data.orderStatusOptions,

  reservations: (state) => state.data.reservations,
  reservationStatuses: (state) => state.data.reservationStatusOptions,

  stats: (state) => {
    const { orderCount, offerCount } = state.data;
    return { orderCount, offerCount };
  },

};
