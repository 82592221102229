import defaultState from '../default/state';
import defaultMutations from '../default/mutations';
import actions from './actions';
import { formatDate } from '@/utils/Helper';

export default {
  namespaced: true,

  state: () => ({
    ...defaultState,
  }),

  getters: {

    customers: (state) => {
      return state?.data?.customers?.map((item) => ({
        ...item,
        created_at: formatDate(item.created_at),
        point: item.point || 0
      })) || [];
    },

  },

  mutations: {
    ...defaultMutations
  },

  actions: {
    ...actions,
  }

};
